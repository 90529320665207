<script setup lang="ts">
import type { PdpEcommerceBlockProductDetailsProps } from '@design-system/components/Pdp/EcommerceBlock/Product/PdpEcommerceBlockProductDetails.props'
import type { ProductCardHorizontal } from '@integration-layer/modules/05.productServices/runtime/components/WrapperProductTile.props'
import type { TemplatesPdpProps } from './TemplatesPdp.props'
import { PDP_YOU_MAY_ALSO_LIKE_ID } from '@integration-layer/data/xGenIds'
import { SIDEBAR_IDS } from '@design-system/data/sidebarIds'

const props = defineProps<TemplatesPdpProps>()
const emit = defineEmits<{
  'update:selectedSizeIndex': [selectedSizeIndex: number | null]
  'update:selectedCountry': [selectedCountry: string]
  'update:selectedColor': [selectedColor: string]
  'update:selectedExtSku': [selectedExtSku: string]
  'update-wishlist': []
}>()

const { addToCartPayload } = useAddToCart()
const { dispatchAddToCartEvent } = useGACartEvents()
const { openDialog } = useDialog()
const { getLocale } = useI18n()
const locale = getLocale()
// for demo purposes
const { recentlyViewedFromCookie } = useRecentlyViewed()

const { currentWebsite } = useWebsite()
const { isShoppable } = await useIsShoppable()
const productDetailsBlockElement = ref<HTMLElement>()
const breadcrumbsTopDistance = ref('')

/* NOTE: const height below is taking a fallback the same height value used in desktop-breadcrumbs scss class ( 600px ), 
which is choosen as approxymately default value for the element's height */

const setBreadcrumbsTopDistance = () => {
  const height = productDetailsBlockElement.value?.clientHeight ?? 600
  breadcrumbsTopDistance.value = `top: max(calc(100dvh - 3rem), calc(${height}px + 4.5rem))`
}

useResizeObserver(productDetailsBlockElement, () => {
  setBreadcrumbsTopDistance()
})

onMounted(() => {
  window.addEventListener('resize', setBreadcrumbsTopDistance)

  scrollIntoPageView({
    scrollBothStrips: true,
    dynamicScrollPlp: false,
  })
  const scrollData = JSON.parse(localStorage.getItem('scrollYVal') ?? '{}')
  scrollData.prodPage = true
  scrollData.scroll = `productTile-${props.productId}`
  localStorage.setItem('scrollYVal', JSON.stringify(scrollData))

  // Check if selected product is already in the recentlyViewed cookie.
  // if not, add it to the cookie like first element on the array
  // if the array is > 30 the latest object is deleted
  if (!recentlyViewedFromCookie.value.includes(props.productId)) {
    recentlyViewedFromCookie.value.unshift(props.productId)
  }

  if (recentlyViewedFromCookie.value.length > 30) {
    recentlyViewedFromCookie.value.pop()
  }
})

onUnmounted(() => {
  window.removeEventListener('resize', setBreadcrumbsTopDistance)
})

const { breadcrumbs } = useBreadcrumbs({
  dataOrigin: 'algolia',
  currentPageTitle: props.title,
  productCategories: props.mainCategory,
})

const linkToMainCategory = computed(() => {
  const arrBc = breadcrumbs.value
  const result = arrBc?.find(item => {
    if (!props.mainCategory?.length) return false
    const getLast = props.mainCategory[props.mainCategory.length - 1]
    return item?.label.toLowerCase() === getLast?.name?.toLowerCase()
  })

  return result?.url
})

const oneSize = computed(() => {
  return props.sizes?.[`${props.selectedCountry}`]?.[0]?.['size'] === 'TU'
})

const { y } = useWindowScroll()
const { breakpoint } = useProjectBreakpoint()
const isLarge = breakpoint.greaterOrEqual('lg')
const isMobile = breakpoint.smaller('md')

const stripMaxHeight = useCssVar('--marketing-strip-max-height')
const monogramMaxHeight = useCssVar('--monogram-strip-height')

const ZERO_INDEX_HEIGHT =
  removePx(monogramMaxHeight.value) + removePx(stripMaxHeight.value)

const isScrolled = computed(() => y.value > ZERO_INDEX_HEIGHT && !isLarge.value)

const handleOpenSidebar = (sidebarId: string) => openDialog(sidebarId)

const handleSimilarProductsEvent = () => {
  const id = 'similarProducts'
  scrollToAnchor(document.getElementById(id) ? id : 'youMayAlsoLike')
}

const scrollToAnchor = (id: string) => {
  const element = document.getElementById(id)
  if (element) {
    const elementOffsetTop = element.getBoundingClientRect().top
    if (!isMobile.value) {
      const headerMonogram = document.getElementById('header-monogram')
      if (headerMonogram) {
        let headerHeight = headerMonogram?.clientHeight
        const stripsContainer = document.getElementById('strips-container')
        if (stripsContainer) {
          headerHeight = stripsContainer?.clientHeight
        }
        if (y.value < headerHeight) {
          const scrollToPixel = elementOffsetTop - (headerHeight - 20)
          window.scrollTo({ top: scrollToPixel, behavior: 'instant' })
        } else if (y.value === headerHeight) {
          const scrollToPixel = elementOffsetTop + 20
          window.scrollTo({ top: scrollToPixel, behavior: 'instant' })
        } else {
          element.scrollIntoView({ behavior: 'instant' })
        }
      }
    } else {
      window.scrollBy({ top: elementOffsetTop - 30, behavior: 'instant' })
    }
  }
}

const productDetailsProps = computed(
  (): PdpEcommerceBlockProductDetailsProps => ({
    ...props,
    breadcrumbs: breadcrumbs.value ?? [], // ?? prop not being used inside PdpEcommerceBlockProductDetails
    isShoppable: isShoppable.value,
  })
)

const swiperRef = ref(null)
const { height: swiperHeight } = useElementBounding(swiperRef)
const completeTheLookSection = ref(null)

/**
 * Quick Buy Popup
 */
const { openQuickBuySlide } = useUIQuickBuyPopUpSlide()

const openQuickBuyPopUpLayer = (product: ProductCardHorizontal) => {
  openQuickBuySlide({
    product,
    isQuickBuyPlp: true,
  })
}

const _dispatchAddToCartEvent = () => {
  if (!addToCartPayload.value) return
  dispatchAddToCartEvent({ view: 'product_page' })
}

const breadcrumbLabel = computed(() => {
  if (!breadcrumbs.value || breadcrumbs.value.length - 2 < 0) return ''
  return breadcrumbs.value.at(-2)?.label
})

/* Pdp 3D image */
const { openDialog: open3dImageDialog } = useDialog(SIDEBAR_IDS.pdp3dImage)
const product3dImage = computed(() => {
  return props.orderedGallery.find(item => item.type === 'TResponsiveImage3D')
})

// Adds the delivery key to view the editorial module in order to use the LogicWrapper
const updatedEditorialDeliveryKeys = computed(() => {
  return [
    ...(props.editorialDeliveryKeys ?? []),
    `${currentWebsite.value}-service-strip`,
  ]
})
</script>

<template>
  <div>
    <PdpEcommerceBlockContainer
      id="pdp-main-block"
      class="carousel-container-overflowed"
      :class="{ giorgioArmaniHeaderPadding: offsetTop }"
      :hide-mobile-topbar="isScrolled"
    >
      <template #top-bar>
        <UtilsGoBack :to="linkToMainCategory">
          <template #default>
            {{ breadcrumbLabel }}
          </template>
        </UtilsGoBack>
        <button
          v-if="product3dImage"
          class="-m-4 p-4"
          :aria-label="$ts('aria-labels.3dImage')"
          @click="open3dImageDialog()"
        >
          <DefaultIcons3DNew class="h-6 w-6" :aria-hidden="true" />
        </button>
      </template>
      <template #left-side-pdp>
        <!-- NOTE: in :class => h-[calc(100% - 18px (current breadcrumbs height) - 24px (padding))] 
                   Added pb-md and -mb-md classes ( 24px, relied on the padding used on the calc above ) 
                   to maintain the space from breadcrumbs
        -->
        <div class="lg:flex lg:h-[calc(100%-42px)] lg:flex-col">
          <div
            ref="productDetailsBlockElement"
            class="pb-md -mb-md lg:sticky lg:top-[--header-default-height]"
          >
            <div class="lg:min-h-[470px]">
              <!-- :style="{
                  marginTop: `${Math.min(y, swiperHeight + 200) - 50}px`,
                }" -->
              <PdpEcommerceBlockProductDetails
                v-show="productDetailsProps"
                is-heading
                class="lg:!mt-0"
                v-bind="productDetailsProps"
                :selected-ext-sku="selectedExtSku"
                :size-grid-code="sizeGridCode"
                :this-product-dims="thisProductDims"
                @update:selected-size-index="
                  emit('update:selectedSizeIndex', $event)
                "
                @update:selected-country="
                  emit('update:selectedCountry', $event)
                "
                @update:selected-ext-sku="emit('update:selectedExtSku', $event)"
                @open-sidebar="handleOpenSidebar"
                @similar-products="handleSimilarProductsEvent()"
                @add-to-cart="_dispatchAddToCartEvent"
              />
            </div>
          </div>
          <!-- NOTE: in :class => lg:-mb-[ 18px (breadcrumbs height) + 24px (padding) ] -->
          <div
            class="breadcrumbs-desktop lg:-mb-[42px]"
            :style="breadcrumbsTopDistance"
          >
            <UIBreadcrumbs
              class="col-span-8 hidden max-w-full lg:block"
              :items="breadcrumbs ?? []"
              pdp
            />
          </div>
        </div>
      </template>
      <template #right-side-pdp>
        <div
          ref="swiperRef"
          class="bg-primitives-off-white top-[var(--header-default-height)] col-span-12 shadow lg:sticky lg:top-0 lg:order-2 lg:col-start-4 lg:col-end-10"
        >
          <!-- Main product gallery -->
          <PdpEcommerceBlockGallerySlider
            :gallery="orderedGallery"
            :title="title"
            :price="price"
            :is-complete-the-look-available="!!completeTheLookSection"
            @complete-the-look="scrollToAnchor('completeTheLook')"
          />
          <PdpEcommerceBlockProductName
            class="mt-3.5 lg:hidden"
            :title="title"
            :price="price"
            :old-price="oldPrice"
            :discount="discount"
            :tags="tags"
            :is-shoppable="isShoppable && !infoCommerce"
          />
        </div>
        <div
          class="order-3 col-span-full lg:col-start-10 lg:col-end-13 lg:flex lg:h-full lg:flex-col"
        >
          <!-- Small right gallery with product details  -->
          <div class="lg:sticky lg:top-[--header-default-height] lg:mb-5">
            <PdpEcommerceBlockProductInfo
              :product-code="productId"
              :gallery="orderedGallery"
              :product-details="productDetails"
              :product-care="productCare"
              :editorial-product-focus="editorialProductFocus"
              :product-focus="productFocus"
              @update-wishlist="emit('update-wishlist')"
              @complete-the-look="scrollToAnchor('completeTheLook')"
            />
          </div>
          <div
            v-if="completeTheLookSection"
            class="hidden lg:flex lg:flex-grow"
          >
            <div
              class="complete-the-look-button lg:sticky lg:bottom-8 lg:mt-auto"
            >
              <button
                class="lg:gap-xxs order-3 flex items-center"
                @click="scrollToAnchor('completeTheLook')"
              >
                <span class="text-book-6 uppercase">
                  {{
                    $ts('pdp.productDetails.informationSection.completeTheLook')
                  }}
                </span>
                <DefaultIconsDown class="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </template>
    </PdpEcommerceBlockContainer>

    <DelayHydration>
      <VisenzeRecommendations type="STL" :product-sku="productId">
        <template #default="{ products }">
          <LazyPdpCompleteLook
            v-if="products && products.length"
            id="completeTheLook"
            ref="completeTheLookSection"
            :title="$ts('pdp.relatedProducts.completeTheLook')"
          >
            <template #product>
              <div
                class="cmtl-container flex min-h-full flex-col lg:pr-[--padding-x]"
              >
                <NuxtPicture
                  provider="cloudinary"
                  :src="orderedGallery[0].data as string"
                  :placeholder="[67, 85]"
                  :alt="title"
                  sizes="sm:100vw lg:20vw"
                  class="aspect-pdp-image w-full"
                  :img-attrs="{
                    class: 'h-full w-full object-contain',
                  }"
                  :modifiers="{ aspectRatio: '4:5' }"
                  quality="70"
                  loading="lazy"
                  fit="fill"
                />
                <PdpEcommerceBlockProductDetails
                  v-show="productDetailsProps"
                  class="ml:block hidden !py-0 lg:mt-8"
                  hide-details
                  v-bind="{ ...productDetailsProps, tags: [] }"
                  :selected-ext-sku="selectedExtSku"
                  :this-product-dims="thisProductDims"
                  @update:selected-size-index="
                    emit('update:selectedSizeIndex', $event)
                  "
                  @update:selected-country="
                    emit('update:selectedCountry', $event)
                  "
                  @update:selected-ext-sku="
                    emit('update:selectedExtSku', $event)
                  "
                  @open-sidebar="handleOpenSidebar"
                  @add-to-cart="_dispatchAddToCartEvent"
                />
              </div>
            </template>
            <template #slider>
              <UISliderWithArrows
                v-if="products && products.length"
                :items="products"
                :loop="false"
                has-no-space-between
                :slide-custom-width="`${products.length > 1 ? 'w-6/12' : 'w-full'} lg:!w-1/3`"
                :show-scrollbar="products.length > 3"
                :right-window-edge="products.length > 3"
                :show-navigation-prev-arrow="false"
                :class="{ 'lg:overflow-hidden': products.length <= 3 }"
              >
                <template #default="item">
                  <WrapperProductTile :product="item">
                    <template #default="{ product, productCardHorizontal }">
                      <UIProductTile
                        :disable-swipe-mobile="true"
                        :product="product"
                        @open-quick-buy="
                          () =>
                            productCardHorizontal &&
                            openQuickBuyPopUpLayer(productCardHorizontal)
                        "
                      />
                    </template>
                  </WrapperProductTile>
                </template>
              </UISliderWithArrows>
            </template>
          </LazyPdpCompleteLook>
        </template>
      </VisenzeRecommendations>
    </DelayHydration>

    <DelayHydration>
      <VisenzeRecommendations type="VSR" :product-sku="productId">
        <template #default="{ products }">
          <LazyPdpRelatedProducts
            v-if="products && products.length"
            id="similarProducts"
            :title="$ts('pdp.relatedProducts.similarProducts')"
          >
            <!-- QUI -->
            <UISliderWithArrows :items="products" has-slide-vertical-border>
              <template #default="item">
                <WrapperProductTile :product="item">
                  <template #default="{ product, productCardHorizontal }">
                    <UIProductTile
                      :disable-swipe-mobile="true"
                      :product="product"
                      @open-quick-buy="
                        () =>
                          productCardHorizontal &&
                          openQuickBuyPopUpLayer(productCardHorizontal)
                      "
                    />
                  </template>
                </WrapperProductTile>
              </template>
            </UISliderWithArrows>
          </LazyPdpRelatedProducts>
        </template>
      </VisenzeRecommendations>
    </DelayHydration>

    <DelayHydration>
      <XGenRecommendations
        :prediction="PDP_YOU_MAY_ALSO_LIKE_ID"
        :initial-cl-fetch="10"
      >
        <template #default="{ products }">
          <LazyPdpRelatedProducts
            v-if="products && products.length"
            id="youMayAlsoLike"
            :title="$ts('pdp.relatedProducts.youMayAlsoLike')"
          >
            <!-- QUI -->
            <UISliderWithArrows
              :id="`XSE-${PDP_YOU_MAY_ALSO_LIKE_ID}`"
              :items="products"
              class="XGen_SmartElement"
              has-slide-vertical-border
            >
              <template #default="item">
                <WrapperProductTile :product="item">
                  <template #default="{ product, productCardHorizontal }">
                    <UIProductTile
                      xse-type="rec"
                      :xse-prod-code="product.sku"
                      :disable-swipe-mobile="true"
                      :product="{
                        ...product,
                        linkToProduct: `${product.linkToProduct}?xse_prod_code=${product.sku}&xse=${PDP_YOU_MAY_ALSO_LIKE_ID}`,
                      }"
                      @open-quick-buy="
                        () =>
                          productCardHorizontal &&
                          openQuickBuyPopUpLayer(productCardHorizontal)
                      "
                    />
                  </template>
                </WrapperProductTile>
              </template>
            </UISliderWithArrows>
          </LazyPdpRelatedProducts>
        </template>
      </XGenRecommendations>
    </DelayHydration>

    <div
      v-show="editorialDeliveryKeys && editorialDeliveryKeys.length"
      class="editorial-modules-wrapper"
    >
      <EditorialModulesWrapper
        v-for="deliveryKey of editorialDeliveryKeys"
        :key="deliveryKey"
        :delivery-key="deliveryKey"
      />
    </div>

    <!-- SIDEBARS -->

    <WrapperReserveInStore
      :id-product="productId"
      :image="orderedGallery[0].data as string"
      :product="productDetailsProps"
      :selected-size-index="oneSize ? 0 : selectedSizeIndex"
      :selected-country="selectedCountry"
      :sizes="sizes"
      :upcs="upcs"
      @update:selected-country="emit('update:selectedCountry', $event)"
    />
    <PdpInfoShippingItem />
    <PdpInfoReturnItem />

    <PdpImage3dModal
      v-if="product3dImage"
      :model="product3dImage?.data?.model ?? ''"
    />
  </div>
</template>

<style lang="scss" scoped>
.swiper :deep(.swiper-pagination) {
  text-align: left;
}

.swiper :deep(.swiper-wrapper) {
  @screen lg {
    transform: none !important;
    display: flex !important;
    flex-direction: column !important;
    gap: 0 !important;
  }
}

.swiper :deep(.swiper-slide) {
  background-color: transparent !important;
  padding-bottom: 3rem;
}

@media (min-width: 1024px) {
  .swiper :deep(.swiper-slide) {
    padding: 0 !important;
    height: 100vh !important;
    width: 100% !important;
    display: flex !important;
    align-content: center !important;
  }
}

.ctl-aspect-ration {
  aspect-ratio: auto 121 / 225;
}

.shadow {
  -webkit-box-shadow: 0 0 20px 20px #fbfbfb;
  box-shadow: 0 0 10px 10px #fbfbfb;
}

/*
* Compact the complete look section for devices with an aspect ratio below 16:9
*/
.cmtl-container {
  @screen lg {
    @media (min-aspect-ratio: 16/9) {
      max-width: 52vh;
      min-width: 380px;

      :deep(.title-price-block) {
        gap: 1.5rem;
      }
    }
  }
}

.giorgioArmaniHeaderPadding {
  :deep(.giorgioArmaniHeaderPaddingTop) {
    padding-top: var(--header-default-height);
  }
}

.breadcrumbs-desktop {
  @screen lg {
    top: max(calc(100dvh - 3rem), 600px);
    position: sticky;
    display: flex;
    max-width: 100%;
    margin-top: 24px;
  }
}

.complete-the-look-button {
  @screen lg {
    top: max(calc(100dvh - var(--header-default-height)), 400px);
    // bottom: unset;
  }
}
</style>
