export const SEARCH_DEPLOYMENT_ID =
  'f998f418-144e-4b01-bdaf-4cd3fee16125' as const

export const TRENDING_SEARCHES_IDS = [
  {
    predictionId: 'sm_el_bb1f49b608cb4e8889af5a67c8e11524',
    name: 'Giorgio Armani',
    id: 'giorgio-armani',
  },
  {
    predictionId: 'sm_el_10518bba64b34d329f2701063b3235c3',
    name: 'Emporio Armani',
    id: 'emporio-armani',
  },
  {
    predictionId: 'sm_el_5835d21df6b44c839f37950e2e57fbb4',
    name: 'EA7',
    id: 'ea7',
  },
  {
    predictionId: 'sm_el_dddc4cfb6bea487496a150e43f9ffc8c',
    name: 'Armani Exchange',
    id: 'armani-exchange',
  },
  {
    predictionId: 'sm_el_7719e5203e91463d9fe6d6c82a40b613',
    name: 'Armani Casa',
    id: 'armani-casa',
  },
] as const

export const TRENDING_SEARCHES_NO_RESULTS_IDS = [
  {
    predictionId: 'sm_el_db97d3c3ab4a4ee196c0cf52265f6d10',
    name: 'Giorgio Armani',
    id: 'giorgio-armani',
  },
  {
    predictionId: 'sm_el_fe593157445a4c61b1705334db14c71b',
    name: 'Emporio Armani',
    id: 'emporio-armani',
  },
  {
    predictionId: 'sm_el_d53c85eb15cf48f5b64e0d7014209e64',
    name: 'EA7',
    id: 'ea7',
  },
  {
    predictionId: 'sm_el_2e735a8042d94cc2ac391e267073c71c',
    name: 'Armani Exchange',
    id: 'armani-exchange',
  },
  {
    predictionId: 'sm_el_cccdeaa1c3c04811a2fb7923cc00db22',
    name: 'Armani Casa',
    id: 'armani-casa',
  },
] as const

export const PDP_YOU_MAY_ALSO_LIKE_ID = 'sm_el_207bb7a99c814e06a331bf90eba6b187'

export const MY_ACCCOUNT_SELECTED_FOR_YOU_ID =
  'sm_el_a7b81c4aa53a42c891a8d4a519b96844'

export const CART_YOU_MAY_ALSO_LIKE_IDS = {
  armaniCasa: 'sm_el_ce1229522ed345adb15ee5d672afab97',
  armaniExchange: 'sm_el_7169bd5b05504f188288a6f0bea50b3f',
  ea7: 'sm_el_f7397d89051447dba54bfc9de13d86d7',
  emporioArmani: 'sm_el_5163e98311134c08bb01bac7423c2475',
  giorgioArmani: 'sm_el_bca581c4b2a549d3a2f240ff6b9aa56a',
}
