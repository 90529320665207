<script setup lang="ts">
import { SIDEBAR_IDS } from '@design-system/data/sidebarIds'
import type { PdpEcommerceBlockInfoAndDetailsSideSlideProps } from './PdpEcommerceBlockInfoAndDetailsSideSlide.props'

const { productDetails } =
  defineProps<PdpEcommerceBlockInfoAndDetailsSideSlideProps>()

const { ts } = useI18n()
</script>

<template>
  <OrganismsSidebarSlide
    :id="SIDEBAR_IDS.pdpEcommerceblockProductInfoAndDetailsSideSlide"
    :header-props="{
      titleText: $ts('pdp.productDetails.infoAndDetails'),
    }"
  >
    <template #body>
      <div class="gap-lg lg:gap-xl flex flex-col pb-12">
        <!-- DESCRIPTION -->
        <div class="gap-xs flex flex-col items-start">
          <UtilsInfo
            :max-character="300"
            :content="productDetails.editoralDescription"
            :expanded-label="
              ts('pdp.productDetails.informationSection.readMore')
            "
            :reduced-label="
              ts('pdp.productDetails.informationSection.readLess')
            "
          />
        </div>
        <!-- COMPOSITION PRODUCT -->
        <div v-if="productDetails?.composition" class="text-light-6">
          {{
            productDetails?.composition
              ? productDetails?.composition.charAt(0).toUpperCase() +
                productDetails?.composition.slice(1).toLowerCase()
              : ''
          }}
        </div>
        <!-- TECHNICAL DETAILS -->
        <div class="text-light-6">
          <ul
            v-for="techInfo in productDetails.technicalDetails"
            :key="techInfo"
          >
            <li>
              <UtilsMarkdown :content="techInfo" />
            </li>
          </ul>
        </div>
        <!-- PRODUCT CODE -->
        <div class="gap-xxs flex flex-col">
          <div class="text-medium-6">
            {{ $ts('pdp.productDetails.informationSection.productCode') }}
          </div>
          <div class="text-light-6">
            {{ productDetails.SkuCode }}
          </div>
        </div>
        <!-- PRODUCT FIT -->
        <!-- <div
          v-if="!!productDetails.fitDescription"
          class="gap-xxs flex flex-col"
        >
          <div class="text-medium-6">
            {{ $ts('pdp.productDetails.informationSection.productFit') }}
          </div>
          <div class="text-light-6">
            {{ productDetails.fitDescription }}
          </div>
        </div> -->
      </div>
    </template>
  </OrganismsSidebarSlide>
</template>
