<script setup lang="ts">
import type { IconType } from '@design-system/components/Contact/ContactMethod.iconsMapping'
import { iconsMap } from '@design-system/components/Contact/ContactMethod.iconsMapping'
import { SIDEBAR_IDS } from '@design-system/data/sidebarIds'

const { cartContactUsSideSlide, bookAnAppointmentSidebar } = SIDEBAR_IDS

const { isHome, helpContactUs } = useRouteHelper()
const { contactUsDescription, contactMethods, handleContactClick } =
  useContactUsSideSlide()

const { isDialogOpen: isBookAnAppointmentSidebarOpen } = useDialog(
  bookAnAppointmentSidebar
)
</script>

<template>
  <OrganismsSidebarSlide
    :id="cartContactUsSideSlide"
    :header-props="{
      titleText: $ts('contacts'),
    }"
    :force-open="isBookAnAppointmentSidebarOpen"
    :blur-background="isHome"
  >
    <template #body>
      <div class="flex h-full flex-col justify-between">
        <div class="gap-xl mb-xl flex flex-col">
          <p
            v-if="contactUsDescription"
            class="text-light-6 whitespace-pre-wrap"
          >
            {{ contactUsDescription }}
          </p>
          <ul v-if="contactMethods.length" class="flex flex-col gap-4">
            <template
              v-for="{ icon, name, cta, _meta } in contactMethods"
              :key="_meta.name"
            >
              <li class="flex items-center justify-between gap-2">
                <component
                  :is="iconsMap[icon as IconType]"
                  v-if="icon"
                  aria-hidden="true"
                  class="size-6"
                />
                <span class="text-book-6 grow">{{ name }}</span>
                <MoleculesLinkWrapper
                  anatomy="link"
                  :target="cta?.externalLink?.target ?? '_blank'"
                  :aria-label="cta?.ariaLabel"
                  :to="cta?.externalLink?.link || cta?.internalCta?.link"
                  :type="
                    !cta?.externalLink?.link &&
                    cta?.internalCta?.type !== 'slug'
                      ? 'button'
                      : 'a'
                  "
                  :external="!!cta?.externalLink?.link"
                  link-underline
                  @click="handleContactClick(name, cta)"
                >
                  <template #label>
                    {{ cta?.label }}
                  </template>
                </MoleculesLinkWrapper>
              </li>
            </template>
          </ul>
        </div>
        <div class="mb-14 flex flex-col gap-3">
          <MoleculesLinkWrapper
            :aria-label="$ts('checkoutPage.thankYou.needHelp')"
            anatomy="link"
            :to="helpContactUs"
          >
            <template #label>
              {{ $ts('checkoutPage.thankYou.needHelp') }}
            </template>
          </MoleculesLinkWrapper>
        </div>
      </div>
      <BookAnAppointmentSidebar is-nested />
    </template>
  </OrganismsSidebarSlide>
</template>
