<script setup lang="ts">
import type { HelpIconType } from '@design-system/mocks/helpIconTypes'
import { SIDEBAR_IDS } from '@design-system/data/sidebarIds'

const { ts } = useI18n()
const { getAllContacts, openLink } = useContacts()
const { data } = await getAllContacts('all-contact-methods')
const { helpShortcutsIcons } = useShortcuts()
const { helpSection, getCanonicalUrl } = useRouteHelper()
const topHeader = ref<HTMLElement>()
const { height } = useElementSize(topHeader)
const { topHeaderHeight: topHeaderHeightState } = useHeaderAndNavbarHeight()
const { currentWebsite } = useWebsite()
const { tabs, setSelectedIndexFromRoute } =
  await useLegalArea('help-area-pages')

const router = useRouter().currentRoute

const { stores, findStoresByLocation, setCenterMap } = useStoreLocator()
const { cmsContentId } = useHelpArea()

const { data: cmsContentData } = await useInfosWithTitleItem(cmsContentId)

const { data: items } = await useMarketingStrip(
  `${currentWebsite.value}-marketing-strips`
)
const { menuStatus } = useMenu()

const { isDialogOpen: isDialogOpenMiniCart } = useDialog(
  SIDEBAR_IDS.headerSideCart
)
const { isDialogOpen: isDialogOpenLogin } = useDialog(SIDEBAR_IDS.loginSidebar)

const { cmsContentCustomerService: cmsContentSideslideId } = SIDEBAR_IDS

const { slider, handleShortcutClick, updateSliderPosition } =
  useSliderTransition()

const messages = computed(() => items.value?.content.messages)

const tabList = computed(
  () =>
    tabs.value?.filter(
      tab =>
        tab.name &&
        !tab.name.toLowerCase()?.includes('index') &&
        tab?.url?.target?.target
    ) ?? []
)

const currentContactMethods = computed(() =>
  data.value?.content.methods && data.value.content.methods.length > 2
    ? data.value?.content.methods.slice(0, 3)
    : []
)

// Mapping contact methods due to phone number not coming from Amplience
// Filtering to not show phone contact method if phone number is invalid
const mappedContactMethods = computed(() => {
  const mappedMethods =
    data.value?.content.methods
      .filter(
        el =>
          el.cta?.label !== ts('customerService.invalidPhoneNumber') &&
          !el.hideInAllCountries?.value?.help &&
          !el.hideInCountry?.help
      )
      .map(method => {
        return {
          name: method.name,
          description: method.description,
          icon: method.icon,
          cta: {
            label: method.cta?.label ?? '',
            ariaLabel: method.cta?.ariaLabel,
            internalLink: {
              link: method.cta?.internalCta?.link || '',
              type: method.cta?.internalCta?.type,
            },
            externalLink: {
              link: method.cta?.externalLink?.link ?? '',
              target: method.cta?.externalLink?.target,
            },
          },
          hideForAll: !method?.hideInAllCountries?.value?.help,
          hideInCountry: !method?.hideInCountry?.help,
        }
      }) ?? []
  return mappedMethods?.length > 2
    ? mappedMethods?.slice(0, 3)
    : (mappedMethods ?? [])
})

watch(
  height,
  (val, oldVal) => {
    if (val !== oldVal) {
      topHeaderHeightState.value = val
    }
  },
  { immediate: true }
)

const getStoresData = async (store: google.maps.places.PlaceResult) => {
  const coords = {
    lat: store.geometry?.location?.lat() ?? 0,
    lng: store.geometry?.location?.lng() ?? 0,
  }
  stores.value = await findStoresByLocation({
    coords,
  })
  setCenterMap(coords)
}

const handleLocalizeMe = async (e: Coordinates) => {
  stores.value = await findStoresByLocation({
    coords: e,
  })
}

const showShortcuts = computed(() => !router.value.path.includes('contact-us'))

const initialTabSlideIndex = computed(() => {
  return tabs.value
    ?.filter(tab => tab.url.target.target !== 'index')
    .findIndex(tab => {
      if (tab.url.target.target)
        return router.value.path.includes(tab.url.target.target)
    })
})

onMounted(() => {
  setSelectedIndexFromRoute()
  updateSliderPosition(initialTabSlideIndex.value ?? 2)
})

watchEffect(() => {
  updateSliderPosition(initialTabSlideIndex.value)
})

useHead({
  title: `${ts('customerService.title')} | Armani`,
})
</script>

<template>
  <ClientOnly>
    <div class="theme-global">
      <div ref="topHeader" class="top-header z-40">
        <HeaderStripWrapper
          :is-hidden="
            menuStatus?.isActive || isDialogOpenMiniCart || isDialogOpenLogin
          "
        >
          <MarketingStrip :messages="messages" />
        </HeaderStripWrapper>
      </div>
      <HeaderWrapperLogic>
        <template #header="props">
          <HeaderDefault
            v-bind="{ ...props, currentBrand: 'global', staticOnMobile: true }"
          />
        </template>
        <template #navigation="props">
          <HeaderNavigationBar v-bind="props" />
        </template>
      </HeaderWrapperLogic>

      <OrganismsSidebarSlide
        :id="cmsContentSideslideId"
        :header-props="{ titleText: cmsContentData?.content?.title ?? '' }"
      >
        <template #body>
          <UtilsMarkdown :content="cmsContentData?.content?.body ?? ''" />
        </template>
      </OrganismsSidebarSlide>

      <CheckoutShippingPickup
        :header-title="$ts('customerService.allStores')"
        @update:stores-found="getStoresData"
        @localize-address="handleLocalizeMe"
      />

      <div class="h-full w-full md:mb-0">
        <div
          class="grid-standard bg-primitives-off-white py-lg md:py-xl lg:py-4xl print:py-0"
        >
          <main id="main" class="col-span-12 grid lg:col-span-8 lg:col-start-3">
            <div
              v-if="showShortcuts"
              class="gap-xxl flex flex-col md:gap-[72px]"
            >
              <div
                class="gap-y-sm md:align-center md:gap-x-sm flex flex-col justify-between md:flex-row"
              >
                <h1 class="text-neutral-black text-h4 md:w-1/2">
                  {{ $ts('customerService.title') }}
                </h1>
                <!-- TODO: (tracked: #1330) temporarly removed the inputSearch -->
                <!--InputsSearch
                  is-open
                  :show-icon="false"
                  :placeholder="$ts('customerService.findInformations')"
                  class="md:w-1/2"
                /-->
              </div>
              <div
                v-if="showShortcuts"
                class="gap-xs slider-tab scrollbar-hide flex w-full flex-wrap items-center print:hidden"
              >
                <AtomsShortcut
                  v-for="(shortcut, index) in tabList"
                  :key="shortcut.name"
                  :link="helpSection(shortcut.url?.target?.target ?? '')"
                  size="M"
                  class="shortcut-element flex-[1_0_33%] md:flex-1"
                  bg-color="white"
                  :shortcut-index="index"
                  :underline="
                    shortcut.url?.target?.target
                      ? getCanonicalUrl(router.path).endsWith(
                          getCanonicalUrl(shortcut.url?.target?.target)
                        )
                      : false
                  "
                  @link-click="handleShortcutClick(index)"
                >
                  <template #icon>
                    <component
                      :is="
                        helpShortcutsIcons[shortcut.icon as HelpIconType]?.icon
                      "
                      v-if="
                        shortcut?.icon &&
                        Object.keys(helpShortcutsIcons).find(
                          el => el === (shortcut.icon as HelpIconType)
                        )
                      "
                      :aria-hidden="true"
                    />
                  </template>
                  <template #text>
                    <span>
                      {{ shortcut.name }}
                    </span>
                  </template>
                </AtomsShortcut>
                <div
                  v-if="initialTabSlideIndex !== -1"
                  ref="slider"
                  class="slider-tab-border hidden md:block"
                />
              </div>
            </div>
            <slot />
            <div class="bg-neutral-white text-neutral-black p-md md:p-xxl">
              <div class="gap-lg flex flex-col md:flex-row">
                <div class="gap-y-lg md:gap-x-lg flex flex-col md:flex-row">
                  <ContactMethod
                    v-for="method in mappedContactMethods"
                    :key="method?.name"
                    v-bind="method"
                    vertical-card
                    class="text-neutral-black flex-1 bg-transparent"
                    @click="(cta: string, type: string) => openLink(cta, type)"
                  />
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>

      <div class="mb-[var(--navbar-height)] lg:mb-0 print:hidden">
        <FooterMain />
      </div>
    </div>
    <GlobalSlides />
  </ClientOnly>
</template>
