<script setup lang="ts">
import type { EmblaOptionsType } from 'embla-carousel'
import type { UIProductGalleryProps } from './UIProductGallery.props'
import { vTrimmedScroll } from '@design-system/directives/trimmedScroll'
import { usePinch } from '@vueuse/gesture'

const props = withDefaults(defineProps<UIProductGalleryProps>(), {
  currentIndex: 0,
})

usePinch(state => {
  // Previene il comportamento nativo del browser
  state.event.preventDefault()
})

const isZoomEnabled = ref(false)
const isPinchActive = ref(false)
const currentSlideIndex = ref(props.currentIndex)

const emblaApi = ref()

const handleInit = (api: any) => {
  emblaApi.value = api

  api.on('select', () => {
    currentSlideIndex.value = api.selectedScrollSnap()
  })
}

const zoomableEmblaOptions: EmblaOptionsType = {
  axis: 'y',
  startIndex: currentSlideIndex.value,
  watchDrag: () => {
    return !isZoomEnabled.value
  },
}

const handleKeyDown = (event: KeyboardEvent) => {
  if (!emblaApi.value || isZoomEnabled.value) {
    return
  }

  const { key } = event
  const disabledKeys = [
    'Tab',
    'ArrowUp',
    'ArrowLeft',
    'ArrowDown',
    'ArrowRight',
  ]

  const prevKeys = ['ArrowUp', 'ArrowLeft']
  const nextKeys = ['ArrowDown', 'ArrowRight']

  if (!disabledKeys.includes(key)) {
    return
  }

  if (prevKeys.includes(key)) {
    event.preventDefault()
    emblaApi.value.scrollPrev()
  } else if (nextKeys.includes(key)) {
    event.preventDefault()
    emblaApi.value.scrollNext()
  }
}

useEventListener(document, 'keydown', handleKeyDown)

const handleScroll = (dir: string) => {
  if (isZoomEnabled.value || isPinchActive.value) {
    return
  }
  if (dir === 'up') {
    emblaApi.value.scrollPrev()
  } else if (dir === 'down') {
    emblaApi.value.scrollNext()
  }
}
</script>

<template>
  <OrganismsModal
    :id="id"
    :description="$ts('cartPage.imageGallery.title')"
    :uses-highest-z-index="true"
    force-global-theme
  >
    <template #body>
      <div
        id="product-gallery-scroll-container"
        v-trimmed-scroll="{
          container: 'div#product-gallery-scroll-container',
          onScroll: (dir: string) => handleScroll(dir),
          freeScroll: false,
        }"
        class="h-dvh w-full items-center justify-center overflow-hidden"
      >
        <UISliderBase :options="zoomableEmblaOptions" @embla-init="handleInit">
          <div
            v-for="(media, index) in gallery"
            :key="`product-gallery-slide-${media.data}`"
            class="embla__slide bg-primitives-off-white flex h-screen w-full shrink-0 items-center justify-center"
          >
            <UIZoomableImage
              v-if="media.type === 'TResponsiveImage'"
              :src="media.data"
              :alt="`${title} - Slide ${index + 1}`"
              :slide-index="currentSlideIndex"
              full-size
              @toggle-zoom="
                (zoomStatus: boolean) => (isZoomEnabled = zoomStatus)
              "
              @is-pinching="
                (pinchStatus: boolean) => (isPinchActive = pinchStatus)
              "
            />
            <UIFullScreenVideo
              v-else-if="media.type === 'TResponsiveVideo'"
              :src="media.data.url"
            />
          </div>
        </UISliderBase>

        <!-- Pagination -->
        <div
          class="absolute left-4 top-1/2 z-[9999] flex -translate-y-1/2 flex-col items-center md:left-7 lg:left-[51px] xl:left-[67.5px]"
        >
          <div class="mb-5 text-[10px] font-normal leading-[15px]">
            {{ currentSlideIndex + 1 }}
          </div>
          <ul>
            <li
              v-for="(slide, index) in gallery"
              :key="`pagination-item-${index}`"
              class="h-4 w-0.5"
              :class="[
                index === currentSlideIndex
                  ? 'bg-primitives-black'
                  : 'bg-primitives-grey-150',
              ]"
            ></li>
          </ul>
          <div class="mt-5 text-[10px] font-normal leading-[15px]">
            {{ gallery.length }}
          </div>
        </div>
      </div>
    </template>
  </OrganismsModal>
</template>

<style lang="scss" scoped>
.embla__slide {
  flex: 0 0 100%;
  scroll-snap-align: start;
  scroll-snap-stop: always;
}
</style>
