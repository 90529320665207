<script setup lang="ts">
import { FormKitLazyProvider } from '@formkit/vue'
import { countryPrefixes } from '@design-system/data/countryPrefixes'
import type { PdpEcommerceBlockReserveInStoreStepTwoProps } from './PdpEcommerceBlockReserveInStoreStepTwo.props'
const props = defineProps<PdpEcommerceBlockReserveInStoreStepTwoProps>()
const emit = defineEmits<{
  changeStep: [step: number]
  submitReservation: [void]
  seeDetailsClick: [storeCode: string]
}>()
const formData = toRef(props.formPersonalInfo)
const localePath = useLocalePath()
const { ts } = useI18n()
const radioOptions = ref([
  { label: ts('pdp.reserveInStore.personalInfo.gender.ms'), value: 'female' },
  { label: ts('pdp.reserveInStore.personalInfo.gender.mr'), value: 'male' },
  {
    label: ts('pdp.reserveInStore.personalInfo.gender.none'),
    value: 'unknown',
  },
])

const prefixes = countryPrefixes.map(country => ({
  label: `${country?.code} ${country?.dial_code}`,
  value: `${country?.dial_code}`,
}))

const { country } = useRouteHelper()
if (formData.value.phonePrefix === '') {
  const countryPrefix = prefixes.find(prefix => {
    const prefixValueLowerCase = prefix.label.toLowerCase()
    return prefixValueLowerCase.includes(country)
  })

  if (countryPrefix) formData.value.phonePrefix = countryPrefix.value
}

const maxCharacters = 500
const charactersLength = computed(
  () => `${formData.value.message?.length ?? 0}/${maxCharacters}`
)
</script>

<template>
  <div class="flex flex-col gap-10 pb-6">
    
<FormKitLazyProvider config-file="true">
<div class="bg-background-off flex flex-col gap-6 p-6">
      <p class="text-book-6 uppercase">
        {{ $ts('pdp.reserveInStore.selectedStoreFrameTitle') }}
      </p>
      <PdpEcommerceBlockReserveInStoreShopAddress
        :shop="shop"
        @see-details-click="emit('seeDetailsClick', $event)"
      />
      <button
        class="bg-background-secondary text-button-2 h-12 w-40 border"
        @click="emit('changeStep', 1)"
      >
        {{ $ts('pdp.reserveInStore.changeStore') }}
      </button>
    </div>
    <div class="flex flex-col gap-6">
      <p class="text-book-6 uppercase">
        {{ $ts('pdp.reserveInStore.personalInfo.title') }}
      </p>
      <FormKit
        id="RESERVATION_FORM"
        v-model="formData"
        type="form"
        :classes="{
          messages: 'hidden',
          actions: 'hidden',
        }"
        @submit="emit('submitReservation')"
      >
        <div class="pb-6">
          <FormKit
            type="myRadio"
            :options="radioOptions"
            name="gender"
            validation="required"
            :validation-messages="{
              required: `${$ts('pdp.reserveInStore.personalInfo.gender.label')} ${$ts('isRequired')}`,
            }"
            :classes="{
              wrapper:
                '$reset w-full flex items-center mb-1 py-1 cursor-pointer',
              options: 'flex flex-wrap gap-x-10 gap-y-6',
            }"
          />
        </div>

        <div
          class="flex flex-row justify-between gap-6 lg:flex-col lg:gap-0 xl:flex-row xl:gap-6"
        >
          <InputsTextFields
            :label="$ts('pdp.reserveInStore.personalInfo.firstName')"
            name="firstName"
            validation="required"
            :validation-messages="{
              required: `${$ts('pdp.reserveInStore.personalInfo.firstName')} ${$ts('isRequired')}`,
            }"
            validation-visibility="submit"
          />

          <InputsTextFields
            :label="$ts('pdp.reserveInStore.personalInfo.lastName')"
            name="lastName"
            validation="required"
            :validation-messages="{
              required: `${$ts('pdp.reserveInStore.personalInfo.lastName')} ${$ts('isRequired')}`,
            }"
            validation-visibility="submit"
          />
        </div>

        <InputsTextFields
          :label="$ts('pdp.reserveInStore.personalInfo.email')"
          name="email"
          type="email"
          validation="email|required"
          :validation-messages="{
            required: `${$ts('pdp.reserveInStore.personalInfo.email')} ${$ts('isRequired')}`,
            email: $ts('generic.invalidEmail'),
          }"
          validation-visibility="submit"
          autocomplete="email"
        />

        <p class="text-light-6 pb-2">
          {{ $ts('pdp.reserveInStore.personalInfo.phoneMessage') }}
        </p>

        <div class="flex justify-between gap-6">
          <div class="w-2/5">
            <InputsSelect
              id="phonePrefix"
              :label="$ts('pdp.reserveInStore.personalInfo.phonePrefix')"
              role="combobox"
              autocomplete="tel-country-code"
              name="phonePrefix"
              :options="prefixes"
            />
          </div>
          <div class="w-3/5">
            <InputsTextFields
              :label="$ts('pdp.reserveInStore.personalInfo.phoneNumber')"
              name="phoneNumber"
              type="mask"
              autocomplete="tel-national"
              mask="{#|repeat}"
              show-mask="false"
            />
          </div>
        </div>
        <FormKit
          type="textarea"
          :label="$ts('pdp.reserveInStore.personalInfo.message')"
          name="message"
          :help="charactersLength"
          :validation="`length:0,${maxCharacters}`"
          :label-class="{ 'text-neutral-black': true }"
        />
      </FormKit>
      <div class="flex flex-col gap-4">
        <p class="text-light-6">
          {{ $ts('pdp.reserveInStore.personalInfo.disclaimerFirstPart') }}

          <MoleculesLinkWrapper
            anatomy="link"
            :aria-label="
              $ts('pdp.reserveInStore.personalInfo.disclaimerPrivacyPolicyLink')
            "
            :to="localePath('/legal/privacy-policy')"
          >
            <template #label>
              {{
                $ts(
                  'pdp.reserveInStore.personalInfo.disclaimerPrivacyPolicyLink'
                )
              }}
            </template>
          </MoleculesLinkWrapper>
          {{ $ts('pdp.reserveInStore.personalInfo.disclaimerSecondPart') }}
        </p>
        <p class="text-light-6">
          {{ $ts('pdp.reserveInStore.personalInfo.disclaimerPromotions') }}
        </p>
      </div>
    </div>
</FormKitLazyProvider>

  </div>
</template>
