<script setup lang="ts">
import type { Assets, ProductTileProps } from './UIProductTile.props'
import type { Brand } from '@design-system/index'

const props = withDefaults(defineProps<ProductTileProps>(), {
  onlyImg: false,
  orderDefault: true,
  showCtaActions: true,
  fetchPriorityImage: false,
  disableSwipeMobile: false,
  forceWishlistRefresh: true,
  infoCommerce: false,
})

const emit = defineEmits(['open-quick-buy'])

const { ts } = useI18n()
const localePath = useLocalePath()
const { isIos } = useDevice()
const { currentWebsite, isGiorgioArmaniWebsite } = useWebsite()
const { selectedCountry } = useSizeCountry()
const { dispatchSelectItemEvent } = useGASelectItem()
const { wishlistProductCodes, addOrRemoveItem } = useWishlist()

const { breakpoint } = useProjectBreakpoint()
const isMobile = computed(() => breakpoint.isSmallerOrEqual('lg'))
const hoverImage = ref(false)

const colors = computed(() => props.product.colors)
const labels = computed(() => props.product.labels)
const inventory = computed(() => props.product.inventory)
const isAvailableOnline = computed(() => inventory.value.isAvailableOnline)
const isAvailableInStore = computed(() => inventory.value.isAvailableInStore)
const isSoldOut = computed(
  () =>
    !inventory.value.isAvailableOnline && !inventory.value.isAvailableInStore
)

const { isShoppable } = await useIsShoppable()

//work around per centrare le label con l'overflow
const labelsDivRef = ref<HTMLElement | null>(null)
const { x: scrollX } = useScroll(labelsDivRef)
const { width } = useElementBounding(labelsDivRef)

const labelsOverflow = computed(() => {
  return labelsDivRef.value?.scrollWidth || 0
})

const addedToWishlist = ref()

watch(wishlistProductCodes, () => {
  addedToWishlist.value = wishlistProductCodes.value[props.product.sku]
})

onMounted(() => {
  scrollX.value = (labelsOverflow.value - width.value) / 2
  addedToWishlist.value = wishlistProductCodes.value[props.product.sku]
})

// TODO: navigation on desktop and pagination in mobile

const filteredLabels = computed(() =>
  labels.value ? labels.value.slice(0, 3) : []
)

const showLabels = computed(
  () => props.product?.showLabels ?? !isGiorgioArmaniWebsite.value
)

// to remove the 3d model and following jpg from the images
// Skip the image if it's a .glb file or if the previous image was a .glb file
const sortedImages = computed(() => {
  const images = [...props.product.images]
  // First, sort the images by priority
  if (props.orderDefault) {
    images.sort(({ priority: a }, { priority: b }) => a! - b!)
  }
  // Then, filter out the .glb files and the images following them
  return images.filter((image, index) => {
    return !(
      image.URL.includes('.glb') ||
      (index > 0 && images[index - 1].URL.includes('.glb'))
    )
  })
})

const activeImage: Ref<Assets | undefined> = ref()
activeImage.value = sortedImages.value[0]
watch(
  () => props.product.sku,
  () => {
    activeImage.value = sortedImages.value[0]
  }
)

const _dispatchSelectItem = () => {
  dispatchSelectItemEvent({
    sku: props.product.sku,
    name: props.product.name,
    brand: props.product.brand,
    color: props.product.defaultColor,
    price: Number(props.product.price.originalPrice),
    available: props.product.inventory.isAvailableInStore,
    img: getHighestPriorityImage(props.product.images)?.URL ?? '',
    discount: props.product.price.discountedPrice
      ? props.product.price.originalPrice! - props.product.price.discountedPrice
      : 0,
  })
}

const enter = () => {
  if (isMobile.value) return
  hoverImage.value = true
  if (sortedImages.value.length > 1) activeImage.value = sortedImages.value[1]
}

const leave = () => {
  if (isMobile.value) return
  hoverImage.value = false
  activeImage.value = sortedImages.value[0]
}

const prev = () => {
  const getActiveIndex = sortedImages.value.findIndex(
    image => image.URL === activeImage.value?.URL
  )
  activeImage.value =
    getActiveIndex > 0
      ? sortedImages.value[getActiveIndex - 1]
      : sortedImages.value[0]
}

const next = () => {
  const getActiveIndex = sortedImages.value.findIndex(
    image => image.URL === activeImage.value?.URL
  )
  activeImage.value =
    getActiveIndex < sortedImages.value.length - 1
      ? sortedImages.value[getActiveIndex + 1]
      : sortedImages.value[sortedImages.value.length - 1]
}

const swipeElement = ref(null)

const { isSwiping, direction } = useSwipe(swipeElement)

watch(isSwiping, () => {
  if (isSwiping.value && !props.disableSwipeMobile) {
    direction.value === 'left' ? next() : prev()
  }
})

const updateWishlist = () => {
  const size = props.product.wishlistSizeValue
  addOrRemoveItem({
    item: {
      productCode: props.product.sku,
      title: props.product.name,
      path: props.product.linkToProduct,
      selectedColor: props.product.defaultColor,
      selectedSize: size,
      selectedCountry: selectedCountry.value,
      selectedImage: getHighestPriorityImage(props.product.images).URL ?? '',
      productBrand: props.product.brand as Brand,
      price: props.product.price.originalPrice,
      categories: props.product.categories,
      isDiscounted: !!props.product.price.discountedPrice,
      isAvailable: !isSoldOut.value,
      lastInStock: props.product.lastInStock,
      MFC: props.product.MFC ?? '',
      ...(size ? { productSku: props.product.defaultSku } : {}),
    },
    view: 'product_listing_page',
    forceRefresh: props.forceWishlistRefresh,
  })
}

</script>

<template>
  <div class="h-full">
    <div
      v-if="!onlyImg"
      class="outline-stroke-cards-hover bg-primitives-off-white md:hover:bg-background-plp group relative box-border flex h-full flex-col"
    >
      <div
        class="md:group-hover:border-stroke-card-hover relative flex-auto md:group-hover:border-solid"
      >
        <span
          v-if="product.isAvailable3d"
          :aria-label="$ts('aria-labels.3dImage')"
          class="text-text-primary absolute left-4 top-4 z-10 py-[3px] lg:left-6 lg:top-6"
        >
          <DefaultIcons3DNew class="h-6 w-6" :aria-hidden="true" />
        </span>
        <NuxtLink
          ref="swipeElement"
          :to="localePath(product.linkToProduct)"
          :aria-label="`${$ts('accessibility.goTo')} ${product.name} ${$ts('accessibility.pdp')}`"
          class="relative h-full w-full cursor-pointer"
          tabindex="-1"
          aria-hidden="true"
          @click="_dispatchSelectItem"
          @mouseenter="enter"
          @mouseleave="leave"
        >
          <UIProductTileImages
            :active-image="activeImage"
            :images="sortedImages"
            :product="product"
            :hover-image="hoverImage"
            :fetch-priority-image="fetchPriorityImage"
            :disable-swipe-mobile="disableSwipeMobile"
            @prev="prev"
            @next="next"
          />
        </NuxtLink>

        <!-- this wasn't reactive, quick fix that works -->
        <UIWishlistButton
          v-if="addedToWishlist && isShoppable"
          class="absolute right-4 top-4 z-10 lg:right-6 lg:top-6"
          :added-to-wishlist="true"
          :sku="product.sku"
          @on-click="updateWishlist"
        />
        <UIWishlistButton
          v-if="!addedToWishlist && isShoppable"
          class="absolute right-4 top-4 z-10 lg:right-6 lg:top-6"
          :added-to-wishlist="false"
          :sku="product.sku"
          @on-click="updateWishlist"
        />
      </div>
      <div class="relative z-10 flex flex-1 flex-col">
        <div
          class="relative flex justify-center overflow-x-hidden"
          v-if="isShoppable"
        >
          <div
            v-if="showLabels"
            ref="labelsDivRef"
            class="scrollbar-hide mt-5 flex min-h-5 items-end gap-x-2 overflow-x-scroll px-6"
          >
            <span
              v-for="label in filteredLabels"
              :key="label"
              class="text-book-8 bg-primitives-grey-100 text-nowrap px-2 py-[3px]"
            >
              {{ label }}
            </span>
          </div>
        </div>
        <div
          class="md:group-hover:text-text-primary flex w-full flex-1 flex-col gap-4 justify-self-end px-6 pb-6 pt-4"
          :class="{ 'pt-4': showLabels }"
        >
          <div class="flex min-h-full flex-col gap-2 text-center">
            <NuxtLink
              v-if="product.name"
              :to="localePath(product.linkToProduct)"
              class="text-book-6 block h-5 overflow-hidden text-ellipsis text-nowrap uppercase after:absolute after:inset-0 after:content-['']"
              data-testid="link-to-product"
              :aria-label="`${$ts('accessibility.goTo')} ${product.name} ${$ts('accessibility.pdp')}`"
            >
              {{ product.name }}
            </NuxtLink>
            <UIPrice
              v-if="product.price && isShoppable && !product.infoCommerce"
              v-bind="product.price"
            />
            <span
              v-if="colors && !product.infoCommerce"
              class="text-light-6 h-5"
            >
              {{ $tc('plp.colorSelect.color', colors) }}
            </span>
            <div
              v-if="isShoppable && !product.infoCommerce"
              :class="[
                'self-center',
                {
                  'hidden md:block': isAvailableOnline,
                  '!hidden':
                    (isAvailableOnline || isAvailableInStore) &&
                    !showCtaActions,
                },
              ]"
            >
              <AtomsCta
                anatomy="link"
                :disabled="!isAvailableOnline"
                :link-underline="isAvailableOnline"
                :class="{
                  '!opacity-100': !isAvailableOnline,
                }"
                aria-haspopup="dialog"
                @click-handler="isAvailableOnline && emit('open-quick-buy')"
              >
                <template #label>
                  <template v-if="isAvailableOnline">
                    {{ ts('addToCart') }}
                  </template>
                  <template v-else-if="isAvailableInStore">
                    {{ ts('productCard.findInStore') }}
                  </template>
                  <template v-else-if="isSoldOut">
                    {{ ts('productCard.soldOut') }}
                  </template>
                </template>
              </AtomsCta>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-else
      class="outline-stroke-cards-hover bg-neutral-white group relative h-full w-full"
    >
      <NuxtLink
        :aria-label="`${$ts('accessibility.goTo')} ${product.name} ${$ts('accessibility.pdp')}`"
        :to="localePath(product.linkToProduct)"
      >
        <NuxtPicture
          v-if="product.images[0].type === 'TResponsiveImage'"
          provider="cloudinary"
          :src="product.images[0].URL"
          :placeholder="[68, 85]"
          loading="lazy"
          :alt="
            createSlug(product.name, product.defaultColor, '0', product.brand)
          "
          class="aspect-pdp-image h-auto w-full object-contain"
          :modifiers="{ aspectRatio: '4:5' }"
          fit="fill"
          sizes="lg:33vw sm:100vw"
          quality="auto"
        />
        <video
          v-else
          :title="product.name"
          :muted="true"
          :autoplay="true"
          :loop="true"
          controlsList="nofullscreen nodownload"
          webkit-playsinline
          playsinline
          height="850"
          width="680"
          :data-image-index="0"
          class="aspect-pdp-image h-auto w-full object-cover"
        >
          <source
            v-if="!isIos"
            :src="
              optimizeVideo(product.images[0].URL, 'best', undefined, 'webm')
            "
            type="video/webm"
          />
          <source
            :src="optimizeVideo(product.images[0].URL, 'best')"
            type="video/mp4"
          />
        </video>
      </NuxtLink>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.outline-stroke-cards-hover {
  outline: 1px solid var(--colorStrokeCardDefault);
}

.outline-stroke-cards-hover:hover {
  outline: 1px solid var(--colorStrokeCardHover);
  z-index: 1;
}
</style>
