<script setup lang="ts">
import type { Brand } from '@design-system/index'
import type { extSize } from '@design-system/components/Pdp/PdpSizeSelector.props'
import type { Sku } from '@commercelayer/sdk'
import type { ListResponse } from '@commercelayer/sdk'
import type { PdpEcommerceBlockProductFocusSideSlideProps } from '@design-system/components/Pdp/EcommerceBlock/PdpEcommerceBlockProductFocusSideSlide.props'
import type { PdpMeta } from '@integration-layer/composables/useAmplienceSeo'
import { productSchema } from '@integration-layer/.nuxt/armani/product-schema'
import { useParamPage } from '@integration-layer/composables/useGAEvent/useDatalayerSetup/useParamPage'
import { z } from 'zod'
import { withoutTrailingSlash } from 'ufo'
import startCase from 'lodash/startCase'

// // map and update the productCode based on the slug and manual updates
const route = useRoute()
const { country } = useRouteHelper()

const { getAlgoliaProductIdFromSlug } = useProductUrls()
const extSizesFormatted = ref<extSize[]>([])
const thisProductDims = ref('')
const { renderExtSizesFormatted, productDims } = usePdpExtSizesUtils()
const selectedSizeState = useState<extSize>(
  'selectedSizeState',
  () => ({}) as extSize
)

const productSlug = computed(
  () => removeLastEmptyParam(route.params.slug!).at(-1)!
)
const productCode = ref(getAlgoliaProductIdFromSlug(productSlug.value))

watch(productSlug, newVal => {
  productCode.value = getAlgoliaProductIdFromSlug(newVal)
})

//fetch product data and update it based on productCode
const { fetchAndMapProductData, productDataState: pdpProductData } =
  useTemplatePdp()

// This function runs both on client and server. if the code of the cached data is the same as the current product code, it means the server has already cached some data and it should be used

await fetchAndMapProductData(productCode.value, {
  updateState: true,
})

const correctPath: string | null = pdpProductData.value?.productPath ?? null
// check if the translated path of product is same as page path
if (
  !pdpProductData.value ||
  (correctPath &&
    withoutTrailingSlash(correctPath) !== withoutTrailingSlash(useRoute().path))
) {
  throw createError({
    statusCode: 404,
    message: `[ERROR]: PRODUCT ${pdpProductData.value?.productDetails.SkuCode} NOT FOUND BECAUSE INCORRECT PATH`,
    statusMessage: `[ERROR]: PRODUCT ${pdpProductData.value?.productDetails.SkuCode} NOT FOUND BECAUSE INCORRECT PATH`,
  })
}

if (!pdpProductData.value) {
  throw createError({
    statusCode: 404,
    statusMessage: `[ERROR]: Product data is not available`,
    message: `[ERROR]: Product data is not available`,
  })
}

const {
  title,
  brand,
  computedLists,
  selectedColor,
  sizes: skuSizes,
  hasExtSizes,
  orderedGallery,
  rawImages,
  raw3DModels,
  amountFloat,
  currencyCode,
  price,
  discount,
  productPathWithoutLocale,
  productDetails,
  productCategoriesSlugs,
  categories,
  sizesData,
  editorialDeliveryKeys,
  alternateUrls,
  infoCommerce,
  MFC,
  SizeGridCode,
  editorialProductFocus,
} = toRefs(pdpProductData.value)

const schema3DModelURLs = computed(() => {
  return raw3DModels.value.map(i => ({
    '@type': '3DModel',
    name: `${title} - 3D Model`,
    contentUrl: i,
  }))
})

useHead({
  link: [...alternateUrls.value],
})
useSchemaOrg([
  defineProduct({
    name: title,
    brand: {
      '@type': 'Brand',
      name:
        brand.value === 'EA7'
          ? brand.value
          : startCase(brand.value.toLowerCase()),
    },
    image:
      rawImages.value?.length && rawImages.value.length > 0
        ? rawImages.value
        : undefined,
    description: productDetails.value.editoralDescription
      ? productDetails.value.editoralDescription
      : undefined,
    offers: [{ price: amountFloat.value, priceCurrency: currencyCode.value }],
  }),
  ...schema3DModelURLs.value,
])

const updateExtSizesAndDimsInfo = async () => {
  //if there are cached ext sizes, use the cached, otherwise fetch them
  extSizesFormatted.value = await renderExtSizesFormatted(
    productCode.value,
    orderedGallery.value
  )
  thisProductDims.value = productDims.value
}

const mountedPdp = ref(false)

// update CL data and fetch extSizes on client (it's information coming from CommerceLayer)
onMounted(async () => {
  mountedPdp.value = true

  // if (hasExtSizes.value) {
  // Get selectedSizeState from local storage (for extSizes with same global SKU after refresh)
  // i need to load the previous selected size object after refresh when the sku is the same, to avoid
  // adding the wrong size to cart after mount.
  // Right now its not needed because we reset to default size after refresh.

  // const storedSelectedSku = localStorage.getItem('selectedSizeState')
  // const [storedSKU, storedSkuWithSize] = storedSelectedSku?.split('-') ?? []
  // const selectedSizeObj = extSizesFormatted.value.find(
  //   size => size.skuWithSize === storedSkuWithSize
  // )
  // if (storedSelectedSku && selectedSizeState.value) {
  //   try {
  //     if (storedSKU === productCode.value && selectedSizeObj) {
  //       selectedSizeState.value = selectedSizeObj
  //       localStorage.removeItem('selectedSizeState')
  //     }
  //   } catch (error) {
  //     console.error(
  //       'Error parsing selectedSizeState from localStorage:',
  //       error
  //     )
  //   }
  // }
  // }
})

const sizeList = computed(() => computedLists.value.sizeList)

const { sizes, selectedSizeIndex, selectedCountry, selectedSize } =
  useSizeSelector(sizeList, hasExtSizes.value, sizesData.value)

watch(sizes, sizes => {
  if (
    sizes &&
    selectedSizeIndex.value !== null &&
    sizes[selectedCountry.value][selectedSizeIndex.value].disabled
  ) {
    selectedSizeIndex.value = null
  }
})

const loadNewExtProd = async (prod: string | undefined) => {
  if (!prod) return
  productCode.value = prod
  await fetchAndMapProductData(prod, {
    updateState: true,
  })

  // to get correct sku after ext size change
  if (hasExtSizes.value) {
    await updateExtSizesAndDimsInfo()
    selectedSizeIndex.value = selectedSizeState?.value?.value ?? 0

    //Save selectedSizeState to localStorage (for extSizes with same global SKU after refresh)
    // Right now its not needed because we reset to default size after refresh.
    // if (selectedSizeState?.value) {
    //   localStorage.setItem(
    //     'selectedSizeState',
    //     `${selectedSizeState.value.SKU}-${selectedSizeState.value.skuWithSize}` ??
    //       ''
    //   )
    // }

    addToCartPayload.value = selectedSizeState?.value?.skuWithSize
      ? {
          name: pdpProductData.value?.title!,
          sku_code: selectedSizeState?.value?.skuWithSize,
          metadata: {
            size: `${selectedSizeState?.value?.label} (${selectedCountry.value})`,
            color: pdpProductData.value?.selectedColor ?? '',
          },
        }
      : null
  }
}

// ADD TO CART
const { isLoading, error, addToCartPayload } = useAddToCart()

const selectedSkuCode = computed(() => selectedSize.value?.sku ?? null)

watch(
  [selectedSkuCode, selectedCountry],
  ([selectedSkuCode, selectedCountry]) => {
    addToCartPayload.value = selectedSkuCode
      ? {
          name: pdpProductData.value?.title!,
          sku_code: selectedSkuCode,
          metadata: {
            size: `${selectedSize.value!.size!} (${selectedCountry})`,
            color: pdpProductData.value?.selectedColor ?? '',
          },
        }
      : null
  },
  {
    immediate: true,
  }
)

// WISHLIST
const { addOrRemoveItem } = useWishlist()
const updateWishlist = () => {
  const image = orderedGallery.value?.[0]
  const outOfStock = skuSizes.value?.every(
    item => item.avEcom === 0 && item.avRet === 0
  )
  const totalAvailableQty = skuSizes.value.reduce(
    (acc, { avEcom, avRet }) => acc + ((avEcom ?? 0) + (avRet ?? 0)),
    0
  )
  const oneSize =
    skuSizes.value.length === 1
      ? {
          size: skuSizes.value[0].label,
          sku: skuSizes.value[0].SKU,
          country: country.toUpperCase(),
        }
      : null
  const selectedSku = oneSize?.sku ?? selectedSkuCode.value
  addOrRemoveItem({
    item: {
      productCode: productCode.value,
      title: title.value,
      path: productPathWithoutLocale.value,
      selectedColor: selectedColor.value ?? '',
      selectedSize: oneSize?.size ?? selectedSize.value?.size ?? '',
      selectedCountry: oneSize?.country ?? selectedCountry.value ?? '',
      selectedImage: rawImages.value?.[0] ?? '',
      productBrand: brand.value as Brand,
      price: price.value,
      categories: categories.value,
      isDiscounted: !!discount?.value,
      isAvailable: !outOfStock,
      lastInStock: totalAvailableQty === 1,
      MFC: MFC.value,
      ...(selectedSku ? { productSku: selectedSku } : {}),
    },
    view: 'product_page',
    forceRefresh: false,
  })
}

// GA Events
type AlgoliaProductType = z.infer<typeof productSchema>

type ProductState = {
  algoliaProduct: AlgoliaProductType
  commercelayerProduct: ListResponse<Sku>
}

const productStateForGA = useState<ProductState | null>('product-state-for-ga')
if (productStateForGA.value) {
  const { algoliaProduct, commercelayerProduct } = productStateForGA.value

  try {
    // GA4 Tracking
    useGAViewItemEvent(algoliaProduct, commercelayerProduct, {
      waitForDatalayerSetup: true,
    })
    useGAEntityProductPage(algoliaProduct, commercelayerProduct)
    useParamPage('productPage', { item: algoliaProduct })
  } catch (e) {
    console.error('GA TRACKING ON PRODUCT PAGE:', e)
  }
}

const seoExtraData: PdpMeta = {
  type: 'pdp',
  product: title.value,
  collectionName: productCategoriesSlugs.value?.[2],
  gender: productCategoriesSlugs.value?.[0],
}

if (Object.keys(seoExtraData).length)
  await useAmplienceSeo({ extraData: seoExtraData })

watch(mountedPdp, () => {
  if (hasExtSizes.value) {
    updateExtSizesAndDimsInfo()
    selectedSizeIndex.value = selectedSizeState?.value?.value ?? 0
    loadNewExtProd(selectedSizeState?.value?.SKU)
  }
})

const { isGiorgioArmaniThemeDeferred } = await useTheme()
</script>

<template>
  <TemplatesPdp
    v-if="pdpProductData"
    v-model:selected-country="selectedCountry"
    v-model:selected-size-index="selectedSizeIndex"
    v-model:selected-color="selectedColor"
    :editorial-delivery-keys="editorialDeliveryKeys"
    :product-id="productCode"
    :selected-ext-sku="productCode"
    :main-category="pdpProductData?.mainCategory ?? []"
    :title="pdpProductData?.title ?? ''"
    :price="pdpProductData?.price ?? ''"
    :price-cents="pdpProductData?.priceCents"
    :is-retail-exclusive="pdpProductData?.isRetailExclusive"
    :old-price="pdpProductData?.oldPrice"
    :discount="pdpProductData?.discount"
    :tags="pdpProductData?.tags"
    :ordered-gallery="
      pdpProductData?.orderedGallery as unknown as TResponsiveGallery
    "
    :sizes="sizes"
    :size-grid-code="SizeGridCode"
    :info-commerce="infoCommerce"
    :this-product-dims="thisProductDims"
    :ext-sizes="extSizesFormatted"
    :ext-colors="pdpProductData?.extColors"
    :product-route="productCode"
    :color-hex="pdpProductData?.colorHex"
    :side-slide-description="
      pdpProductData?.productDetails?.fitDescription ?? ''
    "
    :color-options="pdpProductData?.altColorList ?? []"
    :product-details="pdpProductData?.productDetails"
    :product-care="pdpProductData?.productCare"
    :editorial-product-focus="editorialProductFocus"
    :product-focus="
      pdpProductData?.productFocus as unknown as PdpEcommerceBlockProductFocusSideSlideProps
    "
    :product-pdf="pdpProductData?.productPdf ?? ''"
    :brand="pdpProductData?.brand ?? ''"
    :sku-sizes="skuSizes"
    :product-categories-slugs="productCategoriesSlugs"
    :upcs="pdpProductData?.upcs"
    :add-to-cart-props="{
      isLoading: isLoading,
      error,
    }"
    :sku-selected="selectedSkuCode ?? productCode"
    :offset-top="isGiorgioArmaniThemeDeferred"
    @update:selected-ext-sku="loadNewExtProd"
    @update-wishlist="updateWishlist"
  />
</template>
