export function useContacts() {
  const localePath = useLocalePath()
  const { openDialog } = useDialog()

  const getContactsPage = async (deliveryKey: string) => {
    const query = {
      locale: localePath(''),
      vse: useRoute().query.vse,
      contentId: useRoute().query.contentId,
      deliveryKey: deliveryKey,
    }

    return useFetch('/api/cms/getContacts', {
      query,
    })
  }

  const getAllContacts = async (deliveryKey: string) => {
    const query = {
      locale: localePath(''),
      vse: useRoute().query.vse,
      contentId: useRoute().query.contentId,
      deliveryKey: deliveryKey,
    }

    return useFetch('/api/cms/getAllContacts', {
      query,
    })
  }

  const openLink = (cta: string, type: string = 'slug') => {
    switch (type) {
      case 'anchor':
        return navigateTo({ hash: cta })
      // @ts-ignore
      case 'sidebar' || 'modal':
        return openDialog(cta)
      default:
        return navigateTo(localePath(cta))
    }
  }

  return {
    getContactsPage,
    getAllContacts,
    openLink,
  }
}
