<script setup lang="ts">
import type { PdpEcommerceBlockGallerySliderProps } from './PdpEcommerceBlockGallerySlider.props'
import { vIntersectionObserver } from '@vueuse/components'
import type { ProductGalleryImage } from '@design-system/components/UI/UIProductGallery.props'
import emblaCarouselVue from 'embla-carousel-vue'

const [emblaRef, emblaApi] = emblaCarouselVue({
  loop: false,
  skipSnaps: true,
  align: 'start',
})

const props = defineProps<PdpEcommerceBlockGallerySliderProps>()
defineEmits(['complete-the-look'])

const { ts } = useI18n()
const { isIos } = useDevice()
const stripMaxHeight = useCssVar('--marketing-strip-max-height')
const monogramMaxHeight = useCssVar('--monogram-strip-height')

const slideIndex = ref(0)
const rerenderKey = ref(0)

const ecommSliderEventBus = useEventBus<number>(
  'ecommerce-block-slider-event-bus'
)

const onIntersectImage: IntersectionObserverCallback = ([
  { isIntersecting, target },
]) => {
  if (isIntersecting) {
    const intersectingSlide = Number(target.getAttribute('data-image-index'))
    ecommSliderEventBus.emit(intersectingSlide)
  }
}

onMounted(() => {
  const api = emblaApi.value
  if (!api) return

  // Get snap positions
  scrollSnaps.value = api.scrollSnapList()

  // Update selected dot on scroll
  api.on('select', () => {
    selectedIndex.value = api.selectedScrollSnap()
  })

  // Set initial selected index
  selectedIndex.value = api.selectedScrollSnap()

  // Reselect on resize to ensure correct index
  window.addEventListener('resize', () => {
    selectedIndex.value = api.selectedScrollSnap()
  })
})

const setSlideIndex = (index: number) => {
  slideIndex.value = index
  // Rerender the gallery inside the OrganismsModal to update the active slide
  // because the OrganismsModal is always mounted
  rerenderKey.value++
}

const togglePause = async (videoId: string) => {
  try {
    const video = document.getElementById(videoId) as HTMLVideoElement | null
    if (video) {
      if (video.paused) {
        await video.play()
      } else {
        video.pause()
      }
    }
  } catch (error) {
    console.log(error)
  }
}

const onIntersectVideo: IntersectionObserverCallback = async ([
  { isIntersecting, target },
]) => {
  const videoTarget = target as HTMLVideoElement

  if (isIntersecting) {
    try {
      await videoTarget.play()
    } catch (error) {
      console.log(error)
    }

    const intersectingSlide = Number(target.getAttribute('data-image-index'))
    ecommSliderEventBus.emit(intersectingSlide)
  } else {
    videoTarget.pause()
  }
}

// End video logic

// Mobile animation logic
const { y } = useWindowScroll()

const { breakpoint } = useProjectBreakpoint()

const isLarge = breakpoint.greaterOrEqual('lg')
const isMobile = breakpoint.isSmallerOrEqual('md')

const ZERO_INDEX_HEIGHT =
  removePx(monogramMaxHeight.value) + removePx(stripMaxHeight.value)

const isScrolled = computed(() => y.value > ZERO_INDEX_HEIGHT && !isLarge.value)

const gallery = computed(() => {
  // Tablet-Mobile viewport
  if (!isLarge.value) {
    return props.gallery.filter(item => item.type !== 'TResponsiveImage3D')
  }
  // Desktop viewport
  return props.gallery
})

// Filter TResponsiveImage for full screen gallery
const fullScreenGallery = computed(() =>
  gallery.value.filter(
    (item): item is ProductGalleryImage =>
      item.type === 'TResponsiveImage' || item.type === 'TResponsiveVideo'
  )
)

// check if the gallery has TResponsiveImage3D
const tResponsiveImage3DIndices = computed(() => {
  const indices: number[] = []
  gallery.value.forEach((item, index) => {
    if (item.type === 'TResponsiveImage3D') {
      indices.push(index)
    }
  })
  return indices
})

const { openDialog } = useDialog()
const productGalleryId = 'product-gallery'

// TODO: (tracked #2243) temporaneamente commentato, non funziona lo scroll, la gallery scatta
const showCompleteTheLook = false

// Add these for dots navigation
const selectedIndex = ref(0)
const scrollSnaps = ref<number[]>([])

// Add method to scroll to specific slide
const scrollTo = (index: number) => {
  emblaApi.value?.scrollTo(index)
}
</script>
<template>
  <!-- Full screen gallery -->
  <UIProductGallery
    :id="productGalleryId"
    :key="rerenderKey"
    :gallery="fullScreenGallery"
    :title="title"
    :current-index="slideIndex"
    :responsive-image3-d-indices="tResponsiveImage3DIndices"
  />
  <!-- Gallery -->
  <div
    class="gallery-slider relative -left-[--padding-x] w-screen lg:left-0 lg:w-auto"
  >
    <div ref="emblaRef" class="embla">
      <div
        class="embla__container flex cursor-grab select-none lg:!transform-none lg:flex-col lg:gap-12"
        grab-cursor
      >
        <div
          v-for="(media, index) in gallery"
          :key="`ecommerce-block-slide-${index}`"
          class="embla__slide w-screen shrink-0 lg:w-auto"
          :class="{
            'scrolling-div border-stroke-card-default border': isScrolled,
            'border-r-transparent': index + 1 !== gallery.length && isScrolled,
          }"
          @click="setSlideIndex(index)"
        >
          <div
            :id="`ecommerce-block-slide-${index}`"
            class="relative flex h-full w-full items-center justify-center overflow-hidden"
          >
            <!-- IF SIMPLE IMAGE -->
            <button
              v-if="media.type === 'TResponsiveImage'"
              class="image-wrapper custom-zoom-cursor-add h-full w-auto"
              :aria-label="`${title} ${$ts('accessibility.slide')} ${index}`"
              aria-haspopup="dialog"
              @click="openDialog(productGalleryId)"
            >
              <NuxtPicture
                v-intersection-observer="[onIntersectImage, { threshold: 0.3 }]"
                provider="cloudinary"
                :data-image-index="index"
                :src="media.data"
                :placeholder="[67, 85]"
                :alt="`${title} ${$ts('accessibility.slide')} ${index}`"
                class="aspect-pdp-image flex h-full w-full items-center justify-center transition-transform duration-300 lg:h-[calc(100vh_-_var(--navbar-height))]"
                sizes="sm:120vw"
                quality="auto:best"
                :modifiers="{ aspectRatio: '4:5' }"
                fit="fill"
                :loading="index > 0 ? 'lazy' : undefined"
                :img-attrs="{
                  class: 'lg:h-full object-contain',
                  fetchpriority: index === 0 ? 'high' : undefined,
                }"
              />
            </button>
            <!-- IF VIDEO -->
            <button
              v-if="media.type === 'TResponsiveVideo'"
              class="image-wrapper relative h-full w-full lg:!h-auto"
              :aria-label="title + ' slide: ' + index"
              aria-haspopup="dialog"
            >
              <video
                :id="`large_videoControl_${index}`"
                v-intersection-observer="[onIntersectVideo, { threshold: 0.3 }]"
                :muted="true"
                :loop="true"
                controlsList="nofullscreen nodownload"
                webkit-playsinline
                playsinline
                :data-image-index="index"
                :poster="posterVideo(media.data.cover)"
                class="aspect-pdp-image custom-zoom-cursor-add videoControl h-full w-full cursor-auto object-cover lg:h-[calc(100vh_-_var(--navbar-height))] lg:object-contain"
                @click="openDialog(productGalleryId)"
              >
                <source
                  v-if="!isIos"
                  :src="`${optimizeVideo(media.data.url, 'best', undefined, 'webm')}#t=0.001`"
                  type="video/webm"
                />
                <source
                  :src="`${optimizeVideo(media.data.url, 'best')}#t=0.001`"
                  type="video/mp4"
                />
              </video>
              <button
                v-if="isScrolled && !isMobile"
                class="absolute right-0 top-0"
                :aria-label="$ts('accessibility.playPause')"
                @click="togglePause(`large_videoControl_${index}`)"
              >
                <DefaultIconsPlayFill class="h-6 w-6" aria-hidden="true" />
              </button>
            </button>
            <!-- IF 3D IMAGE -->
            <button
              v-if="media.type === 'TResponsiveImage3D'"
              class="image-wrapper lg:!h-auto"
              :aria-label="title + ' slide: ' + index"
            >
              <div
                v-if="!isScrolled"
                class="h-[45vh] w-screen lg:h-[calc(100vh_-_var(--navbar-height))] lg:w-[33vw]"
              >
                <PdpImage3dIframe
                  v-intersection-observer="[
                    onIntersectImage,
                    { threshold: 0.3 },
                  ]"
                  :model="media.data.model"
                  :data-image-index="index"
                />
              </div>
              <NuxtPicture
                v-else
                v-intersection-observer="[onIntersectImage, { threshold: 0.3 }]"
                src="/images/sample-3d-logo.webp"
                :data-image-index="index"
                sizes="sm:100vw lg:33vw"
                class="h-full w-full lg:h-[calc(100vh_-_var(--navbar-height))]"
                :alt="title"
              />
            </button>
          </div>
        </div>
      </div>

      <div
        class="bullet-navigation positive-margin-left absolute bottom-8 z-10 flex gap-3 lg:hidden"
      >
        <button
          v-for="(_, index) in gallery"
          :key="index"
          class="h-1 w-1 rounded-full transition-colors"
          :class="[
            selectedIndex === index
              ? 'bg-neutral-black'
              : 'bg-primitives-grey-100',
          ]"
          :aria-label="`Go to slide ${index + 1}`"
        />
      </div>

      <button
        v-if="isCompleteTheLookAvailable"
        class="cta cta-secondary px-xs text-book-8 border-primitives-grey-100 absolute bottom-4 right-[var(--padding-x)] z-10 h-8 text-center uppercase lg:hidden"
        @click="$emit('complete-the-look')"
      >
        {{ ts('pdp.productDetails.informationSection.completeTheLook') }}
      </button>
      <div class="border-primitives-grey-100 border-b lg:hidden" />
    </div>
  </div>
</template>
<style lang="scss" scoped>
.custom-zoom-cursor-add {
  cursor:
    url('@design-system/icons/AddWBkg.svg') 24 24,
    auto;
}

.embla :deep(.embla__slide) {
  background-color: transparent !important;
  height: calc(
    100svh - var(--sticky-bottom-navigation) - var(--header-default-height) - var(
        --product-name-height
      )
  );

  @screen lg {
    padding: 0 !important;
    height: calc(100vh - var(--navbar-height)) !important;
    width: 100% !important;
    display: flex !important;
    align-content: center !important;
  }
}

.embla {
  background-color: #fbfbfb;
}

.embla :deep(.image-wrapper) {
  height: 100%;

  @screen lg {
    height: auto;
  }
}

@keyframes hide {
  from {
    display: flex;
  }

  to {
    transform: translate3D(0, 0, 0);
    opacity: 0;
  }
}

.hide {
  animation: hide 200ms;
  display: none;
}

.zoom {
  border: 0;
  font-size: 20px;
  color: black;
  background: #ffffff;
  cursor: pointer;
  width: 30px;
  padding: 0.75rem 1rem;
  font-weight: bold;
  font-family: Arial, sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
}

.zoom-out {
  border-bottom-left-radius: 10rem;
  border-top-left-radius: 10rem;
  box-shadow: -4px 1px 13px -9px rgb(0 0 0 / 75%);
  padding-left: 1.25rem;
}

.zoom-in {
  margin-right: 1rem;
  border-bottom-right-radius: 10rem;
  border-top-right-radius: 10rem;
  box-shadow: 2px 1px 13px -9px rgb(0 0 0 / 75%);
  padding-right: 1.25rem;
}
</style>
