<script setup lang="ts">
import type { HeaderHighlightsProps } from './HeaderHighlights.props'
import type { Highlight } from './HeaderHighlight.props'
import screens from '@design-system/configs/tailwind/screens'
const { width: windowWidth } = useWindowSize()
const smallerLg = computed(
  () => windowWidth.value < Number(screens['lg'].replace('px', ''))
)

const defaultHighlight: Highlight = {
  media: {
    secure_url: '',
    public_id: '',
  },
  caption: '',
}

const props = withDefaults(defineProps<HeaderHighlightsProps>(), {
  openInside: false,
  hideMobileImg: false,
})

const highlightItems = computed(() => {
  return !props.highlights?.length ? [defaultHighlight] : props.highlights
})
</script>

<template>
  <div
    v-if="!openInside"
    class="mb-6 grid gap-y-6"
    :class="{
      'grid-cols-2 gap-x-6': props.highlights?.length > 3,
      'pt-12': !smallerLg,
    }"
  >
    <div
      v-for="(highlight, index) in highlightItems"
      :key="`highlight-${highlight.media.secure_url}`"
      class="highlights staggered-entrance"
      :style="{ '--order': index }"
    >
      <HeaderHighlight
        v-bind="highlight"
        :class="{
          'hidden lg:block':
            !highlight.media.secure_url ||
            !highlight.media.secure_url.startsWith('http'),
        }"
        class="px-0"
        :aspect-ratio="highlightItems.length > 3 ? '4/5' : '16/9'"
        :hide-mobile-img="hideMobileImg"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.staggered-entrance {
  @apply transform-gpu opacity-0;
  animation: staggered-entrance 0.3s cubic-bezier(0.39, 0.575, 0.565, 1)
    forwards;
  animation-delay: calc(var(--order) * 0.1s);
}

@keyframes staggered-entrance {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
