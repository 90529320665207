<script setup lang="ts">
import type {
  HeaderMenuNavPanelProps,
  FeSubList,
} from './HeaderMenuNavPanel.props'
import { useFocusTrap } from '@vueuse/integrations/useFocusTrap'
import screens from '@design-system/configs/tailwind/screens'

const { width: windowWidth } = useWindowSize()
const smallerLg = computed(
  () => windowWidth.value < Number(screens['lg'].replace('px', ''))
)

const props = withDefaults(defineProps<HeaderMenuNavPanelProps>(), {
  openInside: false,
})
const { toggleLevel, isLevelActive, menuStatus } = useMenu()
const { dispatchMenuEvent } = useGANavigationEvents()

const isPanelActive = computed(() =>
  isLevelActive(props.subList.id, props.panel - 1)
)

const FeSubListItems: FeSubList[] = props.subList.items

watch(isPanelActive, () => {
  for (const subListItem of FeSubListItems) {
    subListItem.show = isPanelActive.value
  }
})

const list = ref<HTMLElement>()
const panelFocus = ref<HTMLElement | null>()
const { activate, deactivate } = useFocusTrap(panelFocus, {
  allowOutsideClick: true,
})
const isVisible = useElementVisibility(panelFocus)
const lock = ref(false)

watch([lock, isPanelActive], () => {
  if (
    isPanelActive.value &&
    lock.value &&
    isVisible.value &&
    hasFocusableElement(panelFocus.value!)
  ) {
    return activate()
  }
  lock.value = false
  return deactivate()
})

onClickOutside(list, (event: any) => {
  lock.value = false
  deactivate()
  if (smallerLg.value) return
  if (!['btn-label', 'toggleMenu'].includes(event?.target?.className)) {
    const parentPanel =
      event.target?.getAttribute('parent-panel') ||
      event.target.offsetParent?.getAttribute('parent-panel')

    if (typeof parentPanel !== 'undefined') {
      menuStatus.value.activeLevels[parentPanel] = ''
    }
  }
})

const awaitHighlightsAnimation = props.subList?.highlights
  ? 0.3 + props.subList?.highlights.length * 0.01
  : 0
</script>

<template>
  <div
    :id="`sub-list-${subList.id}`"
    :parent-id="`list-${listId}`"
    role="dialog"
    :parent-panel="panel"
    class="lv-mega-menu-panel lv-megamenu-list__panel bg-background-secondary"
    :class="[
      {
        active: isPanelActive,
        '!left-0': openInside,
        'border-primitives-grey-100 lg:border-l lg:border-r': !openInside,
      },
    ]"
  >
    <div class="positive-padding overflow-y-scroll">
      <HeaderLogoMenu class="my-10 lg:!hidden" />

      <HeaderHighlights
        v-if="subList.highlights?.length === 1"
        :highlights="subList.highlights"
        :hide-mobile-img="true"
      />

      <div
        class="lv-mega-menu-panel__header"
        :class="{
          'lg:hidden': !openInside,
          'mt-10': openInside,
        }"
      >
        <button
          class="lv-mega-menu-panel__back text-cta-primary text-primitives-grey-200 mb-8 flex items-center gap-2 lg:mb-10"
          :class="{
            'mt-[34px]': subList.highlights?.length !== 1 && !openInside,
            'mt-10': subList.highlights?.length !== 1 && openInside,
          }"
          :aria-label="$ts('accessibility.closeCurrentLevelSubmenu')"
          @click="toggleLevel(subList.id, panel - 1)"
        >
          <DefaultIconsPrevious aria-hidden="true" class="-z-10" />
          <span class="text-book-4">{{ subList.label }}</span>
        </button>
      </div>

      <HeaderHighlights
        v-if="
          subList.highlights && subList.highlights.length > 1 && isPanelActive
        "
        :highlights="subList.highlights || []"
        :class="{ active: isPanelActive }"
      />

      <div v-if="FeSubListItems?.length" class="lv-mega-menu-panel__content">
        <div
          ref="panelFocus"
          class="lv-megamenu-list"
          :class="{ active: isPanelActive }"
        >
          <TransitionGroup
            ref="list"
            name="slide-left"
            tag="ul"
            class="lv-megamenu-list__items lv-list -level2 overflow-hidden"
            :class="{
              'lg:mt-[var(--secondary-menu-mt)]': !subList.highlights,
            }"
            @after-enter="lock = true"
            @after-leave="lock = false"
          >
            <li
              v-for="(item, index) in FeSubListItems"
              v-show="item.show"
              :key="`level-2-${item.label}`"
              class="lv-megamenu-list__item mb-6"
              :style="{
                'transition-delay': `${0.001 + index * 0.05 + awaitHighlightsAnimation}s`,
              }"
            >
              <HeaderBtn
                :item="item"
                :list-id="listId"
                :panel="panel"
                @click="dispatchMenuEvent([...subList.parents, item.label])"
              />

              <!-- Sub Levels Panel -->
              <HeaderMenuNavPanel
                v-if="item.subList"
                :sub-list="item.subList"
                :list-id="item.subList.id"
                :panel="panel + 1"
              />
            </li>
          </TransitionGroup>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
:root {
  --secondary-menu-mt: 100px;
}

.lv-mega-menu-panel {
  @apply invisible absolute left-0 top-0 z-30 flex h-full w-full flex-col opacity-0 lg:left-full;

  &.active {
    @apply visible opacity-100;
  }
}

.slide-left-move,
/* apply transition to moving elements */
.slide-left-enter-active,
.slide-left-leave-active {
  transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
}

.slide-left-enter-from,
.slide-left-leave-to {
  @apply transform-gpu;
  transform: translateY(30px);
  opacity: 0;
}

/* ensure leaving items are taken out of layout flow so that moving
   animations can be calculated correctly. */
.list-leave-active {
  opacity: 1;
}
</style>
