<script setup lang="ts">
import type { XGenRecommendationsProps } from './XGenRecommendations.props.ts'
import type { AlgoliaProductType } from './WrapperProductTile.props.ts'

const props = defineProps<XGenRecommendationsProps>()

const { recommendations } = useXgen()
const { skuPrices, fetchSkuPrices } = useProductTileClPrices()

const products = ref<Array<AlgoliaProductType>>([])

const productItemsDefaultSkuCodes = computed(() => {
  return products.value
    .map(item => item.size?.at(0)?.SKU)
    .filter(item => isNonNullable(item))
})

const productsWithCommerceLayerPrices = computed(() => {
  return products.value.map(product => {
    return {
      ...product,
      clPrices: skuPrices.value?.[product.size[0].SKU ?? ''],
    }
  })
})

onMounted(async () => {
  if (props.prediction) {
    products.value = await recommendations({
      predictionID: props.prediction,
    })

    const hasProducts = products.value && products.value.length
    const hasSkuCodes =
      productItemsDefaultSkuCodes.value &&
      productItemsDefaultSkuCodes.value.length

    if (hasProducts && hasSkuCodes) {
      useGAxGenViewItemListEvent(
        products.value.slice(0, props.initialClFetch),
        props.prediction
      )
      fetchSkuPrices(
        productItemsDefaultSkuCodes.value.slice(0, props.initialClFetch)
      )
    }
  }
})

const loadMore = () => {
  fetchSkuPrices(productItemsDefaultSkuCodes.value)
  useGAxGenViewItemListEvent(products.value, props.prediction)
}
</script>
<template>
  <div class="contents">
    <slot :products="productsWithCommerceLayerPrices" :load-more="loadMore" />
  </div>
</template>
