import { z } from 'zod'
import { viewItemListSchema } from '@integration-layer/schemas/GAEvents/viewItemList'
import { itemSchema } from '@integration-layer/schemas/GAEvents/index'
import snakeCase from 'lodash/snakeCase'

type ViewItemList = z.infer<typeof viewItemListSchema>
type AvailableItem = z.infer<typeof itemSchema>
type BooleanLiteral = 0 | 1
type firstSizeRecap = { size: string; quantity: number }

export const useGAVisenzeViewItemListEvent = (
  visenzeProducts: any,
  carouselType: string
) => {
  if (visenzeProducts.length === 0 || !Array.isArray(visenzeProducts)) {
    console.error('Invalid or missing visenze array.')
    return
  }

  const productList: Array<AvailableItem> = visenzeProducts.map(
    (item, index) => {
      const item_brand = item?.data?.brand
        ? String(item.data.brand).toLowerCase() === 'ea7'
          ? 'EA7'
          : snakeCase(String(item.data.brand))
        : ''

      const sizes = item.data?.size_list || []
      const sizesWithQuantity = sizes.map((size: string) => {
        // Extract avEcom value using regex
        const avEcomMatch = size.match(/avEcom: (\d+)/)
        return {
          size,
          quantity: avEcomMatch ? parseInt(avEcomMatch[1], 10) : 0,
        }
      })
      //quantity and avaiability based on first size available
      const firstSizeInfo = <firstSizeRecap>sizesWithQuantity[0] || {
        size: '',
        quantity: 0,
      }

      const sizeParts = firstSizeInfo.size.split(', ')

      const sizeLabel =
        sizeParts
          .find((part: string) => part.startsWith('label:'))
          ?.replace('label:', '') || ''

      return {
        affiliation: 'armani.com',
        coupon: '',
        discount: 0 as BooleanLiteral,
        index: index + 1,
        item_availability: sizesWithQuantity.find(
          (sizeInfo: firstSizeRecap) => sizeInfo.quantity > 0
        )
          ? 1
          : 0,
        item_brand: item_brand ?? '',
        item_category: '',
        item_category2: '',
        item_category4: '',
        item_discount: 0 as BooleanLiteral,
        item_id: item.product_id ?? '',
        item_img: item.main_image_url ?? '',
        item_list_id: '',
        item_list_name: '',
        item_mfc_id: item.product_id.replaceAll('_', ''),
        item_name: item.data?.title ?? '',
        item_reservable: 0 as BooleanLiteral,
        item_size: sizeLabel ?? '',
        item_variant: item.data?.color ?? '',
        price: parseFloat(item.data?.price?.value ?? 0),
        quantity: firstSizeInfo.quantity ?? 0,
      }
    }
  )

  if (carouselType === 'STL' || carouselType === 'VSR') {
    const event: ViewItemList = {
      event: 'view_item_list',
      eventID: '001',
      ecommerce: {
        item_list_id:
          carouselType === 'STL' ? 'complete_the_look' : 'similar_products',
        item_list_name:
          carouselType === 'STL' ? 'complete_the_look' : 'similar_products',
        items: productList,
      },
    }
    useGASendEvent(viewItemListSchema, event, {
      clearEcommerce: true,
    })
  }
}
