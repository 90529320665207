<script setup lang="ts">
import type { Country } from './Footer/FooterAsideLanguage.props'
import { countries } from '@design-system/configs/countries'
import { SIDEBAR_IDS } from '@design-system/data/sidebarIds'

const { openDialog } = useDialog()
const router = useRouter()
const PathWithoutLangCountry = usePathWithoutLangCountry()
const {
  language: currentLanguage,
  country: currentCountry,
  getCanonicalUrl,
} = useRouteHelper()
const { disable: disableConfirmCountryCookie, isOpen } = useConfirmCountry()

const allCountries = countries
  .map(country => ({
    value: country.code,
    label: country.name,
  }))
  .sort((a, b) => a.label.localeCompare(b.label))

const emit = defineEmits<{
  close: []
}>()

const close = () => {
  disableConfirmCountryCookie()
  emit('close')
}

const selectCountry = () => {
  if (currentSelectedCountry.value) {
    const path = url(currentSelectedCountry.value)
    router.push(getCanonicalUrl(path))
  }

  close()
}

const selectedCountry = ref<string>('')
const initialized = ref<number>(0)

const currentSelectedCountry = computed(() => {
  return countries.find(country => country.code === selectedCountry.value)
})

const url = (country: Country, language?: string) => {
  if (!language) language = country.languages[0].langcode
  return country.isDisabled ||
    (currentCountry === country.code && currentLanguage === language)
    ? '#'
    : country.externalURL ||
        `/${language.toLowerCase()}-${country.code.toLowerCase()}/${
          PathWithoutLangCountry.value
        }`
}

const getCountryByRoute = async () => {
  return router?.currentRoute.value?.path.split('-')?.[1]?.substring(0, 2)
}

const checkSelectedCountry = async () => {
  const country = await getCountryByRoute()
  if (!country) return

  const countryData = countries.find(
    c => c.code.toLowerCase() === country.toLowerCase()
  )

  if (countryData) {
    selectedCountry.value = countryData.code
  }
}

router.beforeEach(() => {
  close()
})

onMounted(async () => {
  await nextTick()
  await checkSelectedCountry()
  initialized.value = 1
})
</script>

<template>
  <div
    v-if="isOpen"
    class="confirm-country bg-neutral-white positive-padding z-[50] gap-8 py-4 md:grid md:grid-cols-6 md:items-center"
  >
    <div
      class="flex w-full justify-between gap-x-2 pb-4 md:col-span-3 md:block md:p-0 lg:col-span-4"
    >
      <p class="text-book-6">
        {{ $ts('confirmCountry.text') }}
      </p>
      <button class="block h-6 w-6 md:hidden" @click="$emit('close')">
        <DefaultIconsClose
          aria-hidden="true"
          class="h-6 w-6"
          role="img"
          :aria-label="$ts('accessibility.notChecked')"
        />
      </button>
    </div>

    <div
      class="flex w-full items-center justify-center gap-x-8 md:col-span-3 lg:col-span-2"
    >
      <div class="relative w-full" v-if="initialized > 0">
        <InputsSelect
          id="confirm-country"
          v-model="selectedCountry"
          :value="selectedCountry"
          class="text-book-6"
          :label="$ts('confirmCountry.countrylabel')"
          name="country"
          validation-visibility="submit"
          :options="allCountries ?? []"
        />
        <button
          class="absolute left-0 top-0 z-10 h-full w-full"
          @click.prevent="openDialog(SIDEBAR_IDS.footerAsideLanguage)"
        ></button>
      </div>

      <button
        class="cta cta-primary cta-l text-button-2"
        @click="selectCountry"
      >
        {{ $ts('confirmCountry.btnLabel') }}
      </button>

      <button class="hidden h-6 w-6 md:block" @click="close">
        <DefaultIconsClose
          aria-hidden="true"
          class="h-6 w-6"
          role="img"
          :aria-label="$ts('accessibility.notChecked')"
        />
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
:deep([data-id='confirm-country']) {
  height: auto !important;
}
</style>
