<script setup lang="ts">
import type { PdpEcommerceBlockGalleryThumbnailsProps } from './PdpEcommerceBlockGalleryThumbnails.props'
import emblaCarouselVue from 'embla-carousel-vue'

defineProps<PdpEcommerceBlockGalleryThumbnailsProps>()

const { isIos } = useDevice()
const ecommSliderEventBus = useEventBus<number>(
  'ecommerce-block-slider-event-bus'
)
const [emblaRef, emblaApi] = emblaCarouselVue({
  align: 'center',
  containScroll: 'keepSnaps',
})

const activeSlide = ref(0)
const counter = ref(0)

const scrollIntoViewWithOffset = (el: HTMLElement, offset: number) => {
  window.scrollTo({
    behavior: 'smooth',
    top:
      el.getBoundingClientRect().top -
      document.body.getBoundingClientRect().top -
      offset,
  })
}

const headerHeight = useCssVar('--header-default-height', null, {
  observe: true,
})

// Go to `#ecommerce-block-slide-${index}`
const scrollIntoView = (index: number) => {
  const el = document.getElementById(`ecommerce-block-slide-${index}`)
  if (el) {
    scrollIntoViewWithOffset(el, removePx(headerHeight.value))
  }
}

ecommSliderEventBus.on(intersectedSlide => {
  activeSlide.value = intersectedSlide

  // this check fixes the bug of thumbnail slider that is not starting from the first slide/index
  if (counter.value === 0 && activeSlide.value !== 0 && window.scrollY < 100) {
    activeSlide.value = 0
    counter.value++
  }
  emblaApi.value?.scrollTo(activeSlide.value)
})
</script>

<template>
  <div ref="emblaRef" class="embla">
    <div class="embla__container flex">
      <div
        v-for="(media, index) in gallery"
        :key="`desktop-gallery-ecomm-block-${index}`"
        class="embla__slide"
      >
        <button
          :aria-label="
            $ts('accessibility.scrollToMedia', {
              n: index,
              total: gallery.length,
            })
          "
          class="h-full w-full"
          :class="{ 'cursor-auto': index === activeSlide }"
          @click="scrollIntoView(index)"
        >
          <UIThumbnail
            :is-last-slide="index === gallery.length - 1"
            :selected="index === activeSlide"
            :border-right="true"
            :class="{
              'active-slide': index === activeSlide,
            }"
            :is-video="media.type === 'TResponsiveVideo'"
          >
            <NuxtPicture
              v-if="media.type === 'TResponsiveImage'"
              :src="media.data"
              provider="cloudinary"
              sizes="68px"
              quality="auto:low"
              :modifiers="{ aspectRatio: '4:5' }"
              :img-attrs="{
                fetchpriority: 'high',
              }"
              fit="fill"
              class="object-contain"
              :alt="media.data"
            />
            <NuxtPicture
              v-else-if="media.type === 'TResponsiveImage3D'"
              src="/images/sample-3d-logo.webp"
              class="object-contain"
              sizes="68px"
              :modifiers="{ aspectRatio: '4:5' }"
              fit="fill"
            />
            <video
              v-if="media.type === 'TResponsiveVideo'"
              :id="`small_videoControl_${index}`"
              :muted="true"
              :loop="true"
              controlsList="nofullscreen nodownload"
              webkit-playsinline
              playsinline
              autoplay
              :poster="posterVideo(media.data.cover)"
              :data-image-index="index"
              class="h-full w-full object-contain"
            >
              <source
                v-if="!isIos"
                :src="optimizeVideo(media.data.url, 'best', undefined, 'webm')"
                type="video/webm"
              />
              <source
                :src="optimizeVideo(media.data.url, 'best')"
                type="video/mp4"
              />
            </video>
          </UIThumbnail>
        </button>
      </div>
      <div class="hidden w-[--padding-x] shrink-0 lg:block"></div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.embla__slide {
  flex: 0 0 68px;
  height: 85px;
}
</style>
