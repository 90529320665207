<script setup lang="ts">
import type {
  OpeningHours,
  PdpEcommerceBlockStoreDetailsProps,
} from '@design-system/components/Pdp/EcommerceBlock/PdpEcommerceBlockStoreDetails.props'
import type { ReserveShop } from '@design-system/components/Pdp/EcommerceBlock/ReserveInStore/PdpEcommerceBlockReserveInStoreShopAddress.props'
import { submitForm } from '@formkit/core'
import type { WrapperReserveInStoreProps } from './WrapperReserveInStore.props'
import { SIDEBAR_IDS } from '@design-system/data/sidebarIds'
import type { ColorOption } from '@design-system/components/Pdp/PdpColorSelector.props'

const props = defineProps<WrapperReserveInStoreProps>()

const emit = defineEmits<{
  'update:selectedCountry': [selectedCountry: string]
  sendAddToCart: []
}>()

const { ts } = useI18n()

const { currentWebsite } = useWebsite()
const { customer } = await useCustomer()
const { fetchReserve } = await useBookAndReserve()
const { checkTokenReCAPTCHA } = useRecaptchaSite()
const { addSnackbar } = useHeaderSnackbars()

const { country } = useRouteHelper()

const { closeDialog: closeFindAndReserve, isDialogOpen: isOpenFindAndReserve } =
  useDialog(SIDEBAR_IDS.wrapperReserveInStore)

const {
  isDialogOpen: isStoreDetailsSidebarOpen,
  openDialog: openStoreDetails,
} = useDialog(SIDEBAR_IDS.pdpEcommerceBlockStoreDetails)

const { isDialogOpen: isColorSelectorOpen } = useDialog(
  `${SIDEBAR_IDS.pdpColorSelectorReserveInStore}-${props.idProduct}`
)

const isAnyNestedSidebarOpen = computed(
  () => isColorSelectorOpen.value || isStoreDetailsSidebarOpen.value
)

const RESERVATION_FORM_ID = 'RESERVATION_FORM'

const step = ref(0)

const isNotSelectedSize = ref(false)
const reserveStoreSelectedSizeIndex = ref<number>()
const checkAvailabilityLoading = ref(false)

const reserveShops = ref<ReserveShop[]>([])
const isNotSelectedStore = ref(false)
const selectedStore = ref<ReserveShop>()
const storeCode = ref<string>()

const formPersonalInfo = ref({
  gender: customer?.value?.gender ?? '',
  firstName: customer?.value?.firstName ?? '',
  lastName: customer?.value?.lastName ?? '',
  email: customer?.value?.email ?? '',
  phonePrefix: customer?.value?.prefix ?? '',
  phoneNumber: customer?.value?.phoneNumber ?? '',
  message: '',
})

const storeDetails = ref<PdpEcommerceBlockStoreDetailsProps>()

const sizesNotNullable = computed(() => {
  const upcsAvailable = Object.keys(props.upcs).map(key => key)
  const newSizesRegion = Object.fromEntries(
    Object.entries(props.sizes).map(([region, sizes]) => [
      region,
      sizes.filter(size =>
        upcsAvailable.find(skuFilter => skuFilter === size.sizeCode)
      ),
    ])
  )
  return newSizesRegion
})

const setError = () => {
  addSnackbar(`reserve-in-store-error`, {
    message: {
      key: 'pdp.reserveInStore.errorReserve',
    },
    duration: 5,
    textClasses: 'text-primitives-red',
  })
}

const productUpc = computed(() => {
  if (typeof reserveStoreSelectedSizeIndex.value === 'number') {
    const size = Object.values(sizesNotNullable.value)[0][
      reserveStoreSelectedSizeIndex.value
    ].sizeCode
    return props.upcs[size as string]
  } else {
    return ''
  }
})

const version = computed(() => {
  const today = new Date()
  const year = today.getFullYear()
  const month = String(today.getMonth() + 1).padStart(2, '0')
  const day = String(today.getDate()).padStart(2, '0')
  return `${year}${month}${day}`
})

const stepsObj = ref([
  {
    label: ts('pdp.reserveInStore.checkAvailability'),
    primaryClickHandle: async () => {
      if (reserveStoreSelectedSizeIndex.value !== null) {
        if (!productUpc.value) {
          setError()
          return
        }
        isNotSelectedSize.value = false
        checkAvailabilityLoading.value = true
        const checkAvailabilityResponse = await $fetch(
          '/api/checkAvailability',
          {
            method: 'POST',
            body: { upc: productUpc.value },
            query: { upc: productUpc.value },
          }
        )
        checkAvailabilityLoading.value = false
        if (checkAvailabilityResponse) {
          reserveShops.value = checkAvailabilityResponse
          step.value = 1
        }
      } else {
        isNotSelectedSize.value = true
      }
    },
  },
  {
    label: ts('pdp.reserveInStore.reserve'),
    primaryClickHandle: async () => {
      if (selectedStore.value) {
        storeCode.value = selectedStore.value.code
        await getStoreDetails()

        step.value++
      } else {
        isNotSelectedStore.value = true
      }
    },
  },
  {
    label: ts('pdp.reserveInStore.send'),
    primaryClickHandle: () => {
      submitForm(RESERVATION_FORM_ID)
    },
  },
  {
    label: ts('pdp.reserveInStore.continueShopping'),
    primaryClickHandle: () => {
      closeFindAndReserve()
    },
  },
])

const card = computed(() => ({
  id: props.idProduct,
  info: {
    name: props.product.title,
    price: props.product.price,
    oldPrice: props.product.oldPrice,
    color: props.product.selectedColor,
  },
  path: '#',
  quantity: 1,
  itemTotalPrice: props.product.price,
  image: props.image,
  metadata: 'global',
  available: true,
  skuCode: props.idProduct,
  returnId: '',
}))

const selectedSize = computed(() => {
  if (reserveStoreSelectedSizeIndex.value != null)
    return sizesNotNullable.value[props.selectedCountry][
      reserveStoreSelectedSizeIndex.value
    ].sizeCode
})

const getStoreDetails = async () => {
  const getStoreDetailResponse = await $fetch('/api/yext/getStoreDetails', {
    query: {
      v: version.value,
      c_siteID: storeCode.value,
    },
  }).catch(e => console.log(e))

  if (getStoreDetailResponse) {
    const singleStore = getStoreDetailResponse[0]
    storeDetails.value = {
      name: singleStore?.c_internalName ?? '',
      address: {
        city: singleStore?.address?.city ?? '',
        countryCode: singleStore?.address?.countryCode ?? '',
        line1: singleStore?.address?.line1 ?? '',
        line2: singleStore?.address?.line2 ?? '',
        postalCode: singleStore?.address?.postalCode ?? '',
        region: singleStore?.address?.region ?? '',
      },
      phone: singleStore?.mainPhone ?? '',
      emails: singleStore?.emails ?? [],
      hours: singleStore?.hours as OpeningHours | null,
    }
  }
}

const onGetStoreDetailsOpenDialog = async (selectedStoreCode: string) => {
  storeCode.value = selectedStoreCode
  await getStoreDetails()
  openStoreDetails()
}

const selectedSizeValue = computed(() => {
  return sizesNotNullable.value[props.selectedCountry][
    reserveStoreSelectedSizeIndex.value ?? 0
  ].size
})

const handleSubmitReservation = async () => {
  try {
    await checkTokenReCAPTCHA('ReserveInStore')
    fetchReserve(
      selectedStore.value!,
      formPersonalInfo.value,
      props.product,
      props.image!,
      props.idProduct,
      selectedSizeValue.value,
      storeDetails.value!.emails[0]
    )

    step.value++
  } catch (error) {
    console.error(error)
  }
}

const updateColorSelected = (colorSelected: ColorOption) => {
  card.value.info.color = colorSelected.color
  card.value.image = colorSelected.thumbnailUrl
}

watch(isOpenFindAndReserve, newVal => {
  if (newVal) {
    step.value = 0
    if (props.selectedSizeIndex != null) {
      reserveStoreSelectedSizeIndex.value = props.selectedSizeIndex
      stepsObj.value[0].primaryClickHandle()
    }
  }
})
</script>

<template>
  <div>
    <OrganismsSidebarSlide
      :id="SIDEBAR_IDS.wrapperReserveInStore"
      :header-props="{
        titleText: $ts('pdp.findReserve'),
      }"
      :footer-props="{
        primaryText: stepsObj[step].label,
        isPrimaryLoading: checkAvailabilityLoading,
      }"
      :force-open="isAnyNestedSidebarOpen"
      @primary-click="stepsObj[step].primaryClickHandle"
    >
      <template #body>
        <div class="flex flex-col gap-10">
          <div v-if="step === 3" class="flex flex-col gap-10">
            <p class="text-book-4">
              {{ $ts('pdp.reserveInStore.thankYou.sidebarTitle') }}
            </p>
            <p class="text-book-6">
              {{ $ts('pdp.reserveInStore.thankYou.sidebarDescription') }}
            </p>
          </div>
          <div class="flex justify-between">
            <UIProductCardHorizontal
              :id="card.id"
              :key="card.id"
              :image="card.image as string"
              :metadata="currentWebsite"
              :disable-gallery="true"
              :enable-quick-buy="false"
              :is-price-visible="true"
              :info="{
                name: card.info.name,
                price: card.info.price,
                oldPrice: card.info.oldPrice,
                color: card.info.color,
                size: selectedSize ? selectedSize : '',
              }"
              :path="card.path"
              :quantity="card.quantity"
              :item-total-price="card.itemTotalPrice"
              :available="card.available"
              :sku-code="card.skuCode"
            />
            <AtomsCta
              v-if="step === 1 || step === 2"
              anatomy="link"
              class="self-start pl-4"
              @click="step = 0"
            >
              <template #label>
                {{ $ts('pdp.edit') }}
              </template>
            </AtomsCta>
          </div>
          <PdpEcommerceBlockReserveInStoreStepZero
            v-if="step === 0"
            v-model="reserveStoreSelectedSizeIndex"
            :product-id="props.idProduct"
            :sizes="sizesNotNullable"
            :color-options="props.product.colorOptions"
            :selected-color="props.product.selectedColor"
            :selected-country="props.selectedCountry"
            :is-not-selected-size="isNotSelectedSize"
            @update:selected-country="emit('update:selectedCountry', $event)"
            @update:model-value="isNotSelectedSize = false"
            @update:selected-color="updateColorSelected($event)"
          />
          <PdpEcommerceBlockReserveInStoreStepOne
            v-if="step === 1"
            v-model="selectedStore"
            :shops="reserveShops"
            :is-not-selected-store="isNotSelectedStore"
            :country="country"
            @see-details-click="onGetStoreDetailsOpenDialog"
            @update:model-value="isNotSelectedStore = false"
          />
          <PdpEcommerceBlockReserveInStoreStepTwo
            v-if="step === 2 && selectedStore"
            :shop="selectedStore"
            :form-personal-info="formPersonalInfo"
            @change-step="step = $event"
            @see-details-click="() => openStoreDetails()"
            @submit-reservation="handleSubmitReservation"
          />
          <PdpEcommerceBlockReserveInStoreStepThree
            v-if="step === 3 && selectedStore"
            :shop="selectedStore"
            :form-personal-info="formPersonalInfo"
            @see-details-click="() => openStoreDetails()"
          />
        </div>
        <PdpEcommerceBlockStoreDetails
          v-if="storeDetails"
          v-bind="storeDetails"
        />
      </template>
    </OrganismsSidebarSlide>
  </div>
</template>
